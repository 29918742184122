

// 加载相关配置
export default {

    namespaced: true,

    mutations: {

        LOADING_SHOW(state){
            state.loading_state = true;
        },
        LOADING_HIDE(state){
            state.loading_state = false;
        },

        ROLL(state, val){
            state.scroll_val = val;
        },

        LEVITATION_SHOW(state){
            state.levitation_show = true;
        },
        LEVITATION_HIDE(state){
            state.levitation_show = false;
        },

        SEARCH_SHOW(state){
            state.search_state = true;
        },

        SEARCH_HIDE(state){
            state.search_state = false;
        },

        SEARCH_EDIT(state, val){
            state.search_val = val;
        }
    },

    state: {
        loading_state: false,
        scroll_val: 0,
        search_state: false,
        search_val: "",
        levitation_show: false
    }
}