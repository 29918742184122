
<template>
    <div id="video">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">视频</div>
        </div>
        <div id="layout-content">
            <div class="layout"></div>
        </div>
    </div>
</template>


<script>
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    export default {
        name: "Video",
        components: {Background},
        data(){
            return{
                background: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom']),
        },
        mounted(){
            this.loadingShow();

            let background = this.wallpaperRandom();
            if(background){
                this.background = background;
                setTimeout( ()=> {
                    this.loadingHide();
                }, 500)
            }
            initialApi.random_wallpaper().then(
                response => {
                    if(response.status === 200){
                        this.background = response.data.route;
                    }
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500)
                },
                error => {
                    this.$notify.error({title: '错误', message: error});
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500)
                }
            )
        }
    }
</script>


<style scoped>
    #video {
        width: 100%;
    }
    #site-title, #layout-content {
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    #layout-content .layout {
        width: 100%;
        margin-top: 2.5em;
        max-width: 1200px;
        position: relative;
        border-radius: 4px;
        background-size: cover !important;
    }
    #layout-content:after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
</style>