<template>
    <div class="tags">
        <div class="tags-title">
            <div class="title-span">{{title}}</div>
        </div>
        <div class="tags-lists">
            <div class="tags-item" v-for="(index, tag) in tags" :key="tag">
                <i class="iconfont icon-circle tags-icon"/>
                <span class="tags-content">
                    <router-link :to="`/tags/${tag}/`">{{tag}}</router-link>
                </span>
                <span class="tags-count">（{{tags[tag]}}）</span>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "TagsContent",
        data(){
            return{
                title: null,
                tags: null
            }
        },
        methods: {
            init(){
                let tages = this.getTags;
                if(JSON.stringify(tages) === "{}") return;
                this.title = "分类 - " + Object.keys(tages).length;
                this.tags = tages;
            }
        },
        computed: {
            ...mapGetters('article', ['getTags'])
        },
        mounted(){
            this.init();
        }
    }
</script>


<style scoped>
    .tags {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }

    .tags-title {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .title-span {
        font-size: 2.37em;
        font-family: "华文楷体";
        font-weight: 1000;
        color: black;
    }

    .classify-lists {
        padding-top: 1.75em;
        width: 100%;
    }
    .tags-item {
        padding-top: .25em;
        padding-bottom: .25em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .tags-icon {
        margin-top: .14em;
        font-weight: 1000;
        color: blue;
    }
    .tags-item .tags-icon:hover {
        color: red;
    }
    .tags-content a {
        font-weight: 500;
        margin-left: .2em;
        font-size: 1.24em;
        font-family: "华文楷体";
        color: black;
        text-decoration: none;
    }

    .tags-item .tags-content a:hover {
        color: #49b1f5;
    }

    .tags-count {
        font-weight: 500;
        margin-left: .2em;
        font-size: .98em;
        font-family: "华文楷体";
        color: black;
    }

</style>