// 该文件用于创建整个项目路径的路由器

import Vue from 'vue';
import {initialApi} from '@/api';
import VueRouter from 'vue-router';
import {handle_article, handle_author, handle_witticism, handle_atlas} from '@/Tools';

// vuex数据存储
import AtlasContent from '@/store/Atlas';
import MediaContent from '@/store/Media';
import PopupContent from '@/store/Popup';
import AuthorContent from '@/store/Author';
import WeatherContent from '@/store/Weather';
import ArticleContent from '@/store/Article';
import WitticismContent from '@/store/Witticism';

// 页面组件
import Home from '@/components/pages/home/Index';
import Tags from '@/components/pages/tags/Index';
import Music from '@/components/pages/music/Index';
import Video from '@/components/pages/video/Index';
import About from '@/components/pages/about/Index';
import Gallery from '@/components/pages/gallery/Index';
import Missing from '@/components/pages/missing/Index';
import Archive from '@/components/pages/archive/Index';
import Article from '@/components/pages/article/Index';
import Classify from '@/components/pages/classify/Index';


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


// 初始化站长信息资源
const informationInitial = (resolve=undefined)=> {
    if(AuthorContent.state.information){
        if(resolve){resolve();}
        return;
    }
    initialApi.stationmaster().then(
        response => {
            if(response.status == 200){
                AuthorContent.state.information = response.data;
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化文章资源
const articleInitial = (resolve=undefined)=> {
    if(ArticleContent.state.lists && ArticleContent.state.lists.length){
        if(resolve){resolve();}
        return;
    }
    initialApi.article().then(
        response => {
            if(response.status === 200){
                ArticleContent.state.lists = handle_article(response.data);
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化作者信息
const authorInitial = (resolve=undefined)=> {
    if(AuthorContent.state.author){
        if(resolve){resolve();}
        return;
    }
    initialApi.author().then(
        response => {
            if(response.status === 200){
                AuthorContent.state.author = handle_author(response.data);
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化天气信息
const weatherInitial = (resolve=undefined)=> {
    if(WeatherContent.state.record){
        if(resolve){resolve();}
        return;
    }
    initialApi.weather().then(
        response => {
            if(response.data){
                WeatherContent.state.record = response.data;
            }else{
                Vue.prototype.$notify.error({title: '错误', message: '初始化天气信息发生未知错误，抢修中…'});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化诗词资源
const poetryInitial = (resolve=undefined)=> {
    if(WitticismContent.state.poetry){
        if(resolve){resolve();}
        return;
    }
    initialApi.poetry().then(
        response => {
            if(response.status === 200){
                WitticismContent.state.poetry = handle_witticism(response.data)
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化随笔资源
const essayInitial = (resolve=undefined)=> {
    if(WitticismContent.state.essay){
        if(resolve){resolve();}
        return;
    }
    initialApi.essay().then(
        response => {
            if(response.status === 200){
                WitticismContent.state.essay = handle_witticism(response.data)
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化背景资源
const wallpaperInitial = (resolve=undefined)=> {
    if(AtlasContent.state.wallpaper_data){
        if(resolve){resolve();}
        return;
    }
    let wallpapers = ['nature', 'anime', 'Illustration', 'videogames', ''];
    initialApi.wallpaper(wallpapers[Math.floor(Math.random() * wallpapers.length)]).then(
        response => {
            if(response.status === 200){
                AtlasContent.state.wallpaper = handle_atlas(response.data, '背景壁纸', '数据来源：www.wallhaven.cc')
                AtlasContent.state.wallpaper_data = response.data;
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化头像资源
const avatarInitial = (resolve=undefined)=> {
    if(AtlasContent.state.portrait_data){
        if(resolve){resolve();}
        return;
    }
    let avatars = ['默认图像', ''];
    initialApi.avatar(avatars[Math.floor(Math.random() * avatars.length)]).then(
        response => {
            if(response.status === 200){
                AtlasContent.state.portrait = handle_atlas(response.data, '用户头像', '数据来源：www.wallhaven.cc')
                AtlasContent.state.portrait_data = response.data;
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化音乐资源
const musicInitial = (resolve=undefined)=> {
    if(MediaContent.state.music){
        if(resolve){resolve();}
        return;
    }
    let musics = ['懵懂无知无畏', '无可觅处、拥挤不下之心', '人世如烟波浩渺之海', '异国他乡月儿圆', '回首向来萧瑟处', ''];
    initialApi.music(musics[Math.floor(Math.random() * musics.length)]).then(
        response => {
            if(response.status === 200){
                MediaContent.state.music = response.data;
            }else{
                Vue.prototype.$notify.error({title: '错误', message: response.data});
            }
            if(resolve){resolve();}
        },
        error => {
            Vue.prototype.$notify.error({title: '错误', message: error});
            if(resolve){resolve();}
        }
    )
}

// 初始化视频资源
const videoInitial = (resolve=undefined)=> {
    if(MediaContent.state.video){
        if(resolve){resolve();}
        return;
    }
    if(resolve){resolve();}
}


const router =  new VueRouter({
    mode: "history",
    routes: [
        {
            name: "missing",
            path: "/404/",
            component: Missing,
            meta: {title: "页面丢失"}
        },
        {
            name: "home",
            path: "/",
            component: Home
        },
        {
            path: "/page/:page?/",
            component: Home,
            props: true
        },
        {
            name: "archive",
            path: "/archive/",
            component: Archive,
            meta: {title: "归档"}
        },
        {
            name: "classify",
            path: "/classify/:classify?/",
            component: Classify,
            meta: {title: "分类"},
            props: true
        },
        {
            name: "tags",
            path: "/tags/:tags?/",
            component: Tags,
            meta: {title: "标签"},
            props: true
        },
        {
            name: "gallery",
            path: "/gallery/:gallery?/:page?/",
            component: Gallery,
            meta: {title: "图册"},
            props: true
        },
        {
            name: "music",
            path: "/music/:which?/",
            component: Music,
            meta: {title: "音乐"},
            props: true
        },
        {
            name: "video",
            path: "/video/",
            component: Video,
            meta: {title: "视频"}
        },
        {
            name: "article",
            path: "/article/:title?/",
            component: Article,
            meta: {title: "文章"},
            props: true
        },
        {
            name: "about",
            path: "/about/",
            component: About,
            meta: {title: "关于作者"}
        }

    ]
})


const commonInitializations = [
    weatherInitial,
    essayInitial,
    poetryInitial,
    wallpaperInitial,
    avatarInitial,
    musicInitial,
    videoInitial
];

const routeSpecificInitializations = {
    home: [],
    page: [],
    archive: [],
    classify: [],
    tags: [],
    gallery: [wallpaperInitial, avatarInitial],
    music: [musicInitial],
    video: [videoInitial],
    article: []
};

router.beforeEach((to, from, next) => {
    PopupContent.state.loading_state = true;
    if (to.matched.length === 0) {
        next("/404/");
        return;
    }

    Promise.all([
        new Promise(resolve => informationInitial(resolve)),
        new Promise(resolve => articleInitial(resolve)),
        new Promise(resolve => authorInitial(resolve))
    ]).then(() => {
        const specificInitializations = routeSpecificInitializations[to.name] || [];
        const allInitializations = [...specificInitializations, ...commonInitializations];

        Promise.all(allInitializations.map(fn => new Promise(resolve => fn(resolve))))
            .then(() => next())
            .catch(error => {
                console.error("Initialization error:", error);
                next();
            });
    });
});

router.afterEach( (to, from)=> {
    // 后置路由守卫
    // 设置页面标题
    document.title = to.meta.title ? (to.meta.title + "|" + AuthorContent.state.information.logo) : AuthorContent.state.information.logo;
    PopupContent.state.loading_state = false;
})


export default router;