<template>
    <div class="local-search">
        <el-dialog
            title="本地文章搜索"
            :visible.sync="search_state"
            width="36em"
            :before-close="cancel"
            center
            :lock-scroll="false"
            :append-to-body="true"
        >
            <el-input v-model.trim="localSearchVal" placeholder="搜索本地文章…"></el-input>
            <el-divider><i :class="localSearchClass"></i></el-divider>
            <div class="local-search-results" v-show="this.search_val">
                <div class="error-result" v-if="! SearchArticle">
                    找不到您查询的内容：<span style="color:red;font-weight:500;">{{localSearchVal}}</span>
                </div>
                <div v-if="result_article.length">
                    <div class="result-item" v-for="(article,index) in result_article" :key="index">
                        <div class="result-title">
                            <i class="iconfont icon-circle"></i>
                            <router-link :to="`/article/${article.title}/`" @click.native="cancel">
                                <div class="result-title-header" v-if="! resultTrue(article.title)">
                                    <span>{{ article.title }}</span>
                                </div>
                                <div class="result-title-header" v-if="resultTrue(article.title)">
                                    <span>{{ article.title | resultFomatFront(that) }}</span>
                                    <span style="color:red">{{that.search_val}}</span>
                                    <span>{{ article.title | resultFomatAfter(that) }}</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="result-introduce" v-if="! resultTrue(article.introduce)">
                            <span>{{ article.introduce }}</span>
                        </div>
                        <div class="result-introduce" v-if="resultTrue(article.introduce)">
                            <span>{{ article.introduce | resultFomatFront(that) }}</span>
                            <span style="color:red">{{that.search_val}}</span>
                            <span>{{ article.introduce | resultFomatAfter(that) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import {mapState, mapGetters, mapMutations} from 'vuex';
    export default {
        name: "LocalSearch",
        data(){
            return {
                that: this,
                local_search_result: "loading",
                result_article : []
            }
        },
        methods: {
            ...mapMutations('popup', {searchHide: 'SEARCH_HIDE',  searchEdit: 'SEARCH_EDIT'}),
            cancel(){
                this.searchHide();
                this.searchEdit("");
            },
            resultTrue(val){
                return val.indexOf(this.search_val) != -1 ? true : false;
            }
        },
        computed: {
            ...mapState('popup', ['search_state', 'search_val']),
            ...mapGetters('article', ['search']),
            localSearchVal: {
                get(){
                    return this.search_val;
                },
                set(val){
                    this.searchEdit(val);
                }
            },
            localSearchClass(){
                let className = "";
                switch(this.local_search_result){
                    case "loading":
                        className = "el-icon-loading";
                        break;
                    case "success":
                        className = "el-icon-s-flag";
                        break;
                    case "error":
                        className = "el-icon-document-delete";
                        break;
                }
                return className;
            },
            SearchArticle(){
                if(! this.search_val){
                    this.local_search_result = "loading";
                    this.result_article = [];
                    return;
                }

                let articleArray = this.search(this.search_val);
                if(articleArray && articleArray.length){
                    this.local_search_result = "success";
                    this.result_article = articleArray;
                    return true;
                }
                this.local_search_result = "error";
                this.result_article = [];
                return false;
            }
        },
        filters: {
            resultFomatFront(val, that){
                return val.slice(0, val.indexOf(that.search_val));
            },
            resultFomatAfter(val, that){
                let seaVal = that.search_val;
                return val.slice(val.indexOf(seaVal) + seaVal.length, val.length);
            }
        }
    }
</script>


<style>
    .el-dialog__header {
        font-weight: 500;
        color: black;
        padding: 15px 25px 10px;
    }
    .el-dialog__title {
        font-size: 25px;
        font-family: "华文行楷";
    }
    .el-dialog--center .el-dialog__body {
        padding: 10px 25px 10px;
    }
</style>

<style scoped>
    .local-search-results {
        max-height: 14em;
        overflow-y: auto;
        padding-bottom: .8em;
    }
    .el-dialog__wrapper {
        width: 100% !important;
        height: 85% !important;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .error-result {
        font-family: "华文楷体";
        font-size: 1.25em;
    }

    .result-item {
        width: 100%;
        height: 7.5em;
    }
    .result-title {
        max-width: 100%;
        overflow: hidden;
        padding-bottom: .3em;
    }
    .result-title i, .result-title .result-title-header {
        float: left;
    }
    .result-title i {
        margin-top: 0.32em;
    }
    .result-title-header {
        margin-left: 0.35em;
        font-family: "华文楷体";
        font-size: 1.42em;
        font-weight: 1000;
        color: black;
        cursor: pointer;
    }
    .result-title i:hover {
        color: red;
    }
    .result-title-header:hover {
        color: aqua;
    }
    .result-introduce {
        font-family: "华文楷体";
        margin-left: 1.40em;
        font-size: 1.1em;
        line-height: 1.5;
        overflow: hidden;
    }
</style>