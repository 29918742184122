<template>
    <div>
        <div class="site-logo">{{getLogo}}  Blog</div>
        <div class="site-subtitle">{{subtitle_val}}</div>
        <div class="site-icon">
            <div class="click-icon" @click="jump">
                <i class="iconfont icon-xiala"></i>
            </div>
        </div>
    </div>
</template>


<script>
    import {debounce} from '@/Tools';
    import {mapGetters} from 'vuex';
    export default {
        name: "SiteInfo",
        props: ["subtitle", "jump", "page"],
        data(){
            return{
                timer: null,
                subtitle_val: "",
                site_logo_text: "",
                currentColorIndex: 0, // 当前颜色索引
                colors: ["#ffffff", "#ff7e5f", "#feb47b", "#11998e", "#38ef7d"], // 动态颜色数组
            }
        },
        methods: {
            getLogoText() {
                const text = this.getLogo;
                if (!text) return;

                const animationStyle = `animation: shadowing ${0.2 * (text.length - 1 + 4)}s ease-in-out infinite alternate;`;
                const characters = [...text];
                const logoText = [];

                for (let i = 0; i < characters.length; i++) {
                    const delay = `${0.2 * i}s`;
                    logoText.push(`<span style="${animationStyle} animation-delay: ${delay}">${characters[i]}</span>`);
                }

                if (characters.length > 0) {
                    logoText.push("&nbsp;");
                    logoText.push(`<span style="${animationStyle} animation-delay: ${0.2 * characters.length}s">B</span>`);
                    logoText.push(`<span style="${animationStyle} animation-delay: ${0.2 * (characters.length + 1)}s">l</span>`);
                    logoText.push(`<span style="${animationStyle} animation-delay: ${0.2 * (characters.length + 2)}s">o</span>`);
                    logoText.push(`<span style="${animationStyle} animation-delay: ${0.2 * (characters.length + 3)}s">g</span>`);
                }

                this.site_logo_text = logoText.join("");
            }
        },
        computed: {
            ...mapGetters('author', ['getLogo'])
        },
        watch: {
            "getLogo": {
                immediate: true,
                handler(val){
                    debounce(this.getLogoText(), 100);
                }
            },
            "subtitle": {
                immediate: true,
                handler(val) {
                    if (this.timer) {
                        clearTimeout(this.timer);
                        this.timer = null;
                    }
                    this.subtitle_val = "";
                    let i = 0;
                    let direction = 1; // 1 for incrementing, -1 for decrementing

                    const updateSubtitle = () => {
                        if (val && val.length) {
                            if (direction === 1 && i < val.length) {
                                this.subtitle_val += val.charAt(i);
                                i++;
                            } else if (direction === -1 && i > 0) {
                                this.subtitle_val = this.subtitle_val.slice(0, -1);
                                i--;
                            }

                            // If we've reached the end, switch direction and pause
                            if (i === val.length && direction === 1) {
                                this.timer = setTimeout(() => {
                                    direction = -1;
                                    updateSubtitle(); // Continue after pause
                                }, 2000 + 250); // Pause for 2-3 seconds, then add the interval time
                                return;
                            } else if (i === 0 && direction === -1) {
                                // 切换颜色
                                this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;
                                this.$el.querySelector('.site-subtitle').style.color = this.colors[this.currentColorIndex];

                                this.timer = setTimeout(() => {
                                    direction = 1;
                                    updateSubtitle(); // Continue after pause
                                }, 2000 + 250); // Pause for 2-3 seconds, then add the interval time
                                return;
                            } else {
                                // Schedule the next update
                                this.timer = setTimeout(updateSubtitle, 250);
                            }
                        } else {
                            clearTimeout(this.timer);
                            this.timer = null;
                        }
                    };

                    // Start the first update immediately
                    this.timer = setTimeout(updateSubtitle, 250);
                }
            }
        }
    }
</script>


<style>
    @keyframes shadowing {
        to {
            color: #9933FA;
            text-shadow: 20px 0 100px #9933FA;
        }
    }
</style>

<style scoped>
    .site-logo, .site-subtitle, .site-icon {
        text-align: center;
        display: flex;
        display: -webkit-flex;
        -webkit-align-items: center;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .site-logo {
        font-family: "华文楷体";
        font-size: 2.85em;
        font-weight: 1000;
        color: rgba(255, 255, 255, .9);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
        background-image: linear-gradient(90deg, #0b0c0c, #29e042 33%, #2e08d4);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .site-subtitle {
        height: 1.5em;
        margin-bottom: -.1em;
        font-family: "华文行楷";
        font-size: 2.02em;
        font-weight: 345;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        display: inherit;
    }
    .site-icon {
        height: 3em;
    }
    .click-icon {
        font-size: 2em;
        color: #eee;
        animation: ripple 1.8s linear infinite;
    }
    .click-icon i {
        cursor: pointer;
    }
    .click-icon .iconfont {
        font-size: 25px;
    }


    @-webkit-keyframes ripple {
       25% {
            font-size: 2.2em;
            font-weight: 1000;
            color: rgba(255, 255, 255, 1);
            transform: translateY(-.5em);
        }
        50%, 100% {
            font-size: 2.0em;
            font-weight: 500;
            color: rgba(255, 255, 255, .6);
            transform: translateY(0em);
        }
        75% {
            font-size: 1.8em;
            font-weight: 100;
            color: rgba(255, 255, 255, .2);
            transform: translateY(.5em);
        }
    }
    @keyframes ripple{
        25% {
            font-size: 2.2em;
            font-weight: 1000;
            color: rgba(255, 255, 255, 1);
            transform: translateY(-.5em);
        }
        50%, 100% {
            font-size: 2.0em;
            font-weight: 500;
            color: rgba(255, 255, 255, .6);
            transform: translateY(0em);
        }
        75% {
            font-size: 1.8em;
            font-weight: 100;
            color: rgba(255, 255, 255, .2);
            transform: translateY(.5em);
        }
    }

</style>