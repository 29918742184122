<template>
    <div id="missing">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="layout-content">
            <div class="layout">
                <div class="miss-cover">
                    <el-image :src="error_cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
                <div class="miss-info">
                    <div class="info_title"><p>404</p></div>
                    <div class="info_subtitle"><p>18禁，博主已私藏，去别处看看吧</p></div>
                    <div class="info_black">
                        <router-link  to="/">
                            <div class="black-home"><i class="iconfont icon-dingwei"></i>返回首页</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    export default {
        name: "Missing",
        props: ['BackTop'],
        components: {Background},
        data(){
            return{
                background: null,
                error_cover: "https://wall.chamas.com.cn/fun-blog_default/default_error_404.webp"
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'})
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom'])
        },
        mounted(){
            this.loadingShow();
            this.BackTop();

            let background = this.wallpaperRandom();
            if(background){
                this.background = background;
            }else{
                initialApi.random_wallpaper().then(
                    response => {
                        if(response.status === 200){
                            this.background = response.data.route;
                        }
                    },
                    error => {
                        this.$notify.error({title: '错误', message: error});
                    }
                )
            }
            setTimeout( ()=> {
                this.loadingHide();
            }, 500)
        }
    }
</script>


<style scoped>
    #missing {
        width: 100%;
    }
    #layout-content {
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
        padding-top: 13em;
        padding-bottom: 2.82em;
    }
    .layout {
        width: 60em;
        height: 22em;
         border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .miss-cover {
        width: 44%;
        height: 100%;
        float: left;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .miss-cover .el-image, .el-image__inner {
        height: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        color: #585858;
    }


    .miss-info {
        width: 56%;
        height: 100%;
        float: right;
        padding-top: 3em;
        padding-bottom: 3em;
        position: relative;
    }
    .info_title, .info_subtitle {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .info_title {
        margin-bottom: -2.5em;
    }
    .info_title p {
        font-size: 10em;
        font-family: "华文行楷";
        color: black;
    }
    .info_subtitle p {
        font-size: 2em;
        font-family: "华文楷体";
        color: black;
    }

    .info_black {
        padding-left: 20%;
        padding-right: 20%;
        margin-top: .6em;
    }
    .info_black a {
        color: #180000;
        text-decoration: none;
    }
    .black-home {
        height: 2.5em;
        width: 100%;
        font-family: "华文楷体";
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        background: skyblue;
    }
    .black-home:hover {
        background: orange;
    }
    .black-home i {
        margin-right: .5em;
    }
</style>