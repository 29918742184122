

// 时钟天气数据相关配置
export default {

    namespaced: true,

    state: {
        record: null
    },

    getters: {

        getRecord: state => {
            return state.record;
        }
    }
}