<template>
    <div class="archive">
        <el-timeline>
            <el-timeline-item v-for="(activity, index) in activities" type="primary" :key="index">
                    <div class="archive-title" v-if="activity.type === 'title'">{{activity.content}}</div>
                    <div class="archive-year" v-if="activity.type === 'year'">{{activity.content}}</div>
                    <div class="archive-article" v-if="activity.type === 'article'">
                        <div class="article-cover">
                            <router-link :to="activity.address">
                                <el-image :src="activity.cover">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </router-link>
                        </div>
                        <div class="article-info">
                            <div class="info-push">{{activity.push}}</div>
                            <div class="info-title"><router-link :to="activity.address">{{activity.title}}</router-link></div>
                        </div>
                    </div>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';
    import {dateValid} from '@/Tools';
    import dayjs from 'dayjs';
    export default {
        name: "ClassifyArchiveContent",
        props: ['tags'],
        data(){
            return{
                activities: null
            }
        },
        methods: {
            init(){
                let articles = this.getTagsArchive(this.tags);
                if(JSON.stringify(articles) === "{}") return;
                let len = 0;
                for(let year in articles){
                    len += articles[year].length;
                }
                if(! len) return;

                let activities = [];
                activities[0] = {
                    type: "title",
                    content: "标签：" + this.tags + " - " + len
                }

                let activitiesItem = [];
                for(let year in articles){
                    if(articles[year].length){
                        let activitieItem = [];
                        activitieItem[0] = {
                            type: "year",
                            content: year
                        }
                        articles[year].forEach( (item)=> {
                            if(item){
                                let title = item.title ? item.title : "标题有误";
                                let article = {
                                    type: "article",
                                    cover: item.cover ? item.cover : "",
                                    title: title,
                                    address: "/article/" + title + "/",
                                    push: dayjs((dateValid(item.dynamic.push) ? (item.dynamic.push.toString().length === 10 ? item.dynamic.push*1000 : item.dynamic.push) : dayjs())).format("YYYY-MM-DD hh:mm:ss")
                                }
                                activitieItem.push(article);
                            }
                        })
                        activitiesItem.unshift.apply(activitiesItem, activitieItem);
                    }
                }
                activities.push.apply(activities, activitiesItem);
                this.activities = activities;
            }
        },
        computed: {
            ...mapGetters('article', ['getTagsArchive']),
        },
        mounted(){
            this.init();
        }

    }
</script>


<style>
    .el-timeline-item .el-timeline-item__node--normal {
        top: 8px;
        left: 0px;
        width: 16px;
        height: 16px;
        border: 1px solid blue;
        background: #aadafa;
    }
    .el-timeline-item .el-timeline-item__tail {
        top: 8px;
        left: 6.5px;
        border-left:  4px solid #aadafa;
    }
    .el-timeline-item .el-timeline-item__wrapper {
        position: relative;
        top: 0px;
    }
</style>

<style scoped>
    .archive {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }

    .el-timeline-item .el-timeline-item__wrapper .archive-title {
        font-size: 1.92em;
        font-family: "华文楷体";
        font-weight: 1000;
        color: black;
    }
    .el-timeline-item .el-timeline-item__wrapper .archive-year {
        padding-top: 2px;
        font-size: 1.72em;
        font-family: "华文楷体";
        font-weight: 800;
        color: rgba(0, 0, 0, .95);
    }

    .archive-article {
        height: 4em;
        position: relative;
    }
    .el-image {
        width: 100%;
        height: 100%;
    }
    .article-cover .el-image {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .article-cover .el-image:hover {
        transform: scale(1.15);
    }
    .article-cover, .article-cover a {
        float: left;
        width: 5.8em;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        color: #585858;
    }
    .article-info {
        float: left;
        margin-left: 5px;
    }
    .article-info .info-title, .article-info .info-title a {
        font-size: 1.2em;
        font-family: "华文楷体";
        font-weight: 100;
        text-decoration: none;
        color: rgba(0, 0, 0, .95);
    }
    .article-info .info-title:hover, .article-info .info-title a:hover {
        color: #49b1f5;
    }
    .article-info .info-push {
        margin-left: 2px;
        font-size: 1.08em;
        font-family: "华文仿宋";
        color: #ACACAC;
    }
</style>