
<template>
    <div id="classify">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">分类</div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="classify-contents">
                    <template v-if="isClassifyTrue"><ClassifyContent/></template>
                    <template v-if="! isClassifyTrue"><ClassifyArchiveContent :classify="classify"/></template>
                </div>
                <div class="aside-contents"><AsidePublic :witticism="witticism"/></div>
            </div>
        </div>
    </div>
</template>


<script>
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    import ClassifyContent from '@/components/pages/classify/Classify.vue';
    import ClassifyArchiveContent from '@/components/pages/classify/ClassifyArchive.vue';
    import AsidePublic from '@/components/aside/AsidePublic.vue';
    export default {
        name: "Classify",
        components: {Background, ClassifyContent, ClassifyArchiveContent, AsidePublic},
        props: ['classify', 'BackTop'],
        data(){
            return{
                background: null,
                witticism: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'})
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom']),
            ...mapGetters('witticism', ['essayRandom']),
            ...mapGetters('article', ['getClassifys']),
            isClassifyTrue(){
                if(! this.classify) return true;
                let classifies = this.getClassifys;
                if(JSON.stringify(classifies) === "{}") return true;
                for(let classify in classifies){
                    if(classify.indexOf(this.classify) != -1) return false;
                }
                this.$router.push("/classify/");
            }
        },
        watch: {
            "classify": {
                immediate: true,
                handler(val){
                    this.loadingShow();
                    let classifies = this.getClassifys;
                    if(! val || JSON.stringify(classifies) === "{}"){
                        this.BackTop();
                        setTimeout( ()=> {
                            this.loadingHide();
                            return;
                        }, 500)
                    }
                    let result = false;
                    classifies = Object.keys(classifies);
                    for(let i=0; i<classifies.length;i++){
                        if(classifies[i].indexOf(val) != -1){
                            result = true;
                            break;
                        }
                    }
                    if(! result) this.$router.push("/classify/");
                    Promise.all([
                        new Promise( (resolve)=> {
                            let background = this.wallpaperRandom();
                            if(background){
                                this.background = background;
                                resolve();
                                return;
                            }
                            initialApi.random_wallpaper().then(
                                response => {
                                    if(response.status === 200){
                                        this.background = response.data.route;
                                    }
                                    resolve();
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        }),
                        new Promise( (resolve)=> {
                            let essay = this.essayRandom();
                            if(essay){
                                resolve(essay);
                                return;
                            }
                            initialApi.random_essay().then(
                                response => {
                                    let _essay;
                                    if(response.status === 200){
                                        _essay = response.data;
                                    }
                                    resolve(_essay ? _essay : null);
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        })
                    ]).then( (res)=> {
                        this.BackTop();
                        setTimeout( ()=> {
                            if(res[1]) this.witticism = res[1];
                            this.loadingHide();
                        }, 500)
                    })
                }
            }
        }
    }
</script>


<style scoped>
    #classify {
        width: 100%;
    }
    #site-title, #layout-content {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
        width: 100%;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
</style>

<style scoped>
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .layout {
        padding-top: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2.5em;
        width: 100%;
        max-width: 1200px;
    }
    .classify-contents {
        width: 76%;
        float: left;
    }
    .aside-contents {
        width: 23%;
        float: right;
        position: -webkit-sticky;
        position: sticky;
        top: -31.5em;
    }
</style>