
                        
// 图册相关配置
export default {

    namespaced: true,

    mutations: {
        PORTRAIT_INIT(state, val){
            state.portrait = val;
        },
        PORTRAIT_DATA_INIT(state, val){
            state.portrait_data = val;
        },

        WALLPAPER_INIT(state, val){
            state.wallpaper = val;
        },
        WALLPAPER_DATA_INIT(state, val){
            state.wallpaper_data = val;
        }
    },

    state: {
        portrait: null,
        portrait_data: null,
        wallpaper: null,
        wallpaper_data: null
    },

    getters: {
        getgallery: state=> {
            return () => [
                ...(state.portrait && Object.keys(state.portrait).length > 0 ? [state.portrait] : []),
                ...(state.wallpaper && Object.keys(state.wallpaper).length > 0 ? [state.wallpaper] : [])
            ];
        },

        getAmount: state => {
            function calculateResult(data) {
                if (!data.length) return;
                return Math.ceil(data.length / 30);
            }
            return (gallery) => {
                switch (gallery) {
                    case 'portrait':
                    return calculateResult(state.portrait_data);
                    case 'wallpaper':
                    return calculateResult(state.wallpaper_data);
                    default:
                    return;
                }
            }
        },
        getPage: state => {
            const getData = (gallery, page) => {
                if (!gallery || !page) return;
                
                const data = {
                    portrait: state.portrait_data,
                    wallpaper: state.wallpaper_data,
                }[gallery];
                
                if (!data || !data.length) return;
                
                const maxPage = Math.ceil(data.length / 30);
                if (page > maxPage) return null; // or throw an error
                
                return data.slice(page * 30 - 30, page * 30);
            };
            
            return (gallery, page) => {
                switch (gallery) {
                    case 'portrait':
                    case 'wallpaper':
                    return getData(gallery, page);
                    default:
                    return;
                }
            };
        },
        getDescribe: state => {
            const getDescribe = (gallery) => {
                if (!gallery) return;
                
                const data = {
                    portrait: state.portrait,
                    wallpaper: state.wallpaper,
                }[gallery];
                
                if (!data || !data.describe) return;
                
                return data.describe;
            };
              
            return (gallery) => {
                switch (gallery) {
                    case 'portrait':
                    case 'wallpaper':
                    return getDescribe(gallery);
                    default:
                    return;
                }
            };
        },

        portraitAll: state=> {
            if(! state.portrait_data) return;
            return state.portrait_data;
        },
        portraitRandom: state=> {
            return (which)=> {
                if(! state.portrait_data) return;
                return state.portrait_data[Math.floor(Math.random() *  (state.portrait_data.length))].route;
            }
        },
        portraitAppoint: state=> {
            return (which) => {
                return state.portrait_data?.filter(item => item.group.includes(which));
            };
        },

        wallpaperAll: state=> {
            if(! state.wallpaper_data) return;
            return state.wallpaper_data;
        },
        wallpaperRandom: state=> {
            return (which)=> {
                if(! state.wallpaper_data) return;
                return state.wallpaper_data[Math.floor(Math.random() *  (state.wallpaper_data.length))].route;
            }
        },
        wallpaperAppoint: state=> {
            return (which) => {
                return state.wallpaper_data?.filter(item => item.group.includes(which));
            };
        },
    }
}