
<template>
    <div id="archive">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">归档</div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="archive-contents"><ArchiveContent :activities="activities"/></div>
                <div class="aside-contents"><AsidePublic :witticism="witticism"/></div>
            </div>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    import {initialApi} from '@/api';
    import {dateValid} from '@/Tools';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    import AsidePublic from '@/components/aside/AsidePublic.vue';
    import ArchiveContent from '@/components/pages/archive/Archive.vue';
    export default {
        name: "Archive",
        props: ["BackTop"],
        components: {Background, ArchiveContent, AsidePublic},
        data(){
            return{
                background: null,
                witticism: null,
                activities: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            archiveInitial() {
                const articles = this.getArchive;
                if (!Object.keys(articles).length) return;

                const activities = [
                    {
                    type: "title",
                    content: `文章总览 - ${Object.values(articles).reduce((acc, year) => acc + year.length, 0)}`
                    }
                ];

                Object.entries(articles).forEach(([year, yearArticles]) => {
                    if (yearArticles.length) {
                    const activitieItem = [
                        {
                        type: "year",
                        content: year
                        },
                        ...yearArticles.map((item) => ({
                        type: "article",
                        cover: item.cover || "",
                        title: item.title || "标题有误",
                        address: `/article/${item.title}/`,
                        push: dayjs(dateValid(item.dynamic.push) ? (item.dynamic.push.toString().length === 10 ? item.dynamic.push*1000 : item.dynamic.push) : dayjs()).format("YYYY-MM-DD hh:mm:ss")
                        }))
                    ];
                    activities.push(...activitieItem);
                    }
                });

                this.activities = activities;
            }
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom']),
            ...mapGetters('witticism', ['essayRandom']),
            ...mapGetters('article', ['getArchive'])
        },
        mounted(){
            this.loadingShow();
            Promise.all([
                new Promise( (resolve)=> {
                    this.archiveInitial();
                    resolve();
                }),
                new Promise( (resolve)=> {
                    let background = this.wallpaperRandom();
                    if(background){
                        this.background = background;
                        resolve();
                        return;
                    }
                    initialApi.random_wallpaper().then(
                        response => {
                            if(response.status === 200){
                                this.background = response.data.route;
                            }
                            resolve();
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            resolve();
                        }
                    )
                }),
                new Promise( (resolve)=> {
                    let essay = this.essayRandom();
                    if(essay){
                        resolve(essay);
                        return;
                    }
                    initialApi.random_essay().then(
                        response => {
                            let _essay;
                            if(response.status === 200){
                                _essay = response.data;
                            }
                            resolve(_essay ? _essay : null);
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            resolve();
                        }
                    )
                })
            ]).then( (res)=> {
                this.BackTop();
                setTimeout( ()=> {
                    if(res[2]) this.witticism = res[2];
                    this.loadingHide();
                }, 500)
            })
        }
    }
</script>


<style scoped>
    #archive {
        width: 100%;
    }
    #site-title, #layout-content {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
        width: 100%;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
</style>

<style scoped>
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .layout {
        padding-top: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2.5em;
        width: 100%;
        max-width: 1200px;
    }
    .archive-contents {
        width: 76%;
        float: left;
    }
    .aside-contents {
        width: 23%;
        float: right;
        position: -webkit-sticky;
        position: sticky;
        top: -31.5em;
    }
</style>