

// 媒体数据相关配置
export default {

    namespaced: true,

    mutations: {
        MUSIC_INIT(state, val){
            state.music = val;
        },
        VIDEO_INIT(state, val){
            state.video = val;
        }
    },

    state: {
        music: null,
        video: null
    },

    getters: {

        musicAll: state=> {
            if(! state.music) return;
            return state.music;
        },
        musicAppoint: state=> {
            return (groups, artists, prefer, languages) => {
                if (!state.music) return [];
                if (!groups && !artists && !prefer && !languages) return state.music;
                
                return state.music.filter(item => {
                    if (groups) return groups.some(group => item.group.toUpperCase().includes(group.toUpperCase()));
                    if (artists) return artists.some(artist => item.artist.toUpperCase().includes(artist.toUpperCase()));
                    if (prefer) return item.prefer === prefer;
                    if (languages) return languages.some(language => item.language.toUpperCase().includes(language.toUpperCase()));
                    return true;
                });
            };
        },
        musicAppointLabel: state=> {
            return (label) => {
                if (!state.music || !label) return [];
                return state.music.filter(item => item.label.toUpperCase().includes(label.toUpperCase()));
            };
        },
    }
}