<template>
    <div class="article-header">
        <div class="header-title">{{articleHeader.title}}</div>
        <div class="header-attribute">
            <div class="attribute-author">
                作者：
                <a  :href="articleHeader.address" target="_bank" class="author-head"><el-avatar size="small" :src="articleHeader.head"></el-avatar></a>
                <a  :href="articleHeader.address" target="_bank" class="author-name">{{authorFomat}}</a>
            </div>
            <div class="attribute-view">
                <i class="el-icon-view"></i>
                浏览量：{{viewsFomat}}
            </div>
        </div>
        <div class="header-date">
            <div class="date-push">
                <i class="el-icon-date"></i>
                发表日期：{{pushFomat}}
            </div>
            <div class="date-update">
                <i class="iconfont icon-lishijilu"></i>
                更新日期：{{updateFomat}}
            </div>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    import {dateValid} from '@/Tools';
    export default {
        name: "ArticleHeader",
        props: ['articleHeader'],
        computed: {
            authorFomat(){
                if (! this.articleHeader.name || (! typeof this.articleHeader.name === "string")) return "作者有误";
                let val = this.articleHeader.name.replace(/(^\s*)|(\s*)$/g,"");
                if(val.length > 13) return val.slice(0, 13);
                return val;
            },
            pushFomat(){
                if(! this.articleHeader.push) return;
                if(! dateValid(this.articleHeader.push)) return dayjs().format("YYYY-MM-DD");

                const timestamp = this.articleHeader.push;
                if (timestamp.toString().length === 10) {
                    return dayjs(timestamp * 1000).format("YYYY-MM-DD");
                } else {
                    return dayjs(timestamp).format("YYYY-MM-DD");
                }
            },
            updateFomat(){
                if(! this.articleHeader.update) return;
                if(! dateValid(this.articleHeader.update)) return dayjs().format("YYYY-MM-DD");

                const timestamp = this.articleHeader.update;
                if (timestamp.toString().length === 10) {
                    return dayjs(timestamp * 1000).format("YYYY-MM-DD");
                } else {
                    return dayjs(timestamp).format("YYYY-MM-DD");
                }
            },
            viewsFomat(){
                if(! this.articleHeader.views) return 0;
                let val = parseInt(this.articleHeader.views);
                if(! /^[0-9]+.?[0-9]*/.test(val)) return 0;
                if(val <= 9999) return val;
                return (val / 10000).toFixed(1) + "w";
            }
        }
    }
</script>


<style scoped>
    .header-title, .header-attribute, .header-date {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .header-title {
        font-family: "华文行楷";
        font-size: 2.5em;
        color: rgba(255, 255, 255, .85);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }

    .header-attribute, .header-date {
        font-family: "华文楷体";
        font-size: 1.18em;
        font-weight: 1000;
        color: rgba(255, 255, 255, .95);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }


    .header-attribute {
        padding-top: .5em;
        padding-bottom: .08em;
    }
    .attribute-author {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .attribute-author .el-avatar--small {
        width: 1.5em;
        height: 1.5em;
        line-height: 15px;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .author-name {
        margin-left: 5px;
        text-decoration: none;
        color: rgba(255, 255, 255, .85);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }

    .attribute-view, .date-update {
        margin-left: 15px;
    }

    .header-date {
        padding-top: .08em;
        padding-bottom: .5em;
    }
</style>