<template>
    <div class="clock">
        <div class="clock-dial">
            <div class="dial-within">
                <div class="dial-weather">
                    <div class="weather-win">{{weatcherWin}}</div>
                    <div class="weather-wea" :class="weaClass">
                        <i class="iconfont" :class="weaIcon"></i>
                    </div>
                    <div class="weather-tem" :class="temClass">
                        <i class="iconfont" :class="temIcon"></i>
                        {{weatcherTemperature}}
                    </div>
                    <div class="weather-humidity" :class="humidityClass" v-if="humidityClass">
                        <i class="iconfont icon-shidu2"></i>
                        {{weatcherHumidity}}
                    </div>
                </div>
                <div class="dial-date">
                    <div class="date-time">
                        {{dateTime}}
                        <span class="time-space">.</span>
                        <span class="time-interval">{{timeInterval}}</span>
                    </div>
                </div>
                <div class="dial-data">
                    <div class="data-day">{{dateToday}}</div>
                    <div class="data-week">{{dateWeek}}</div>
                    <div class="data-city">
                        <i class="iconfont icon-dingwei1"></i>
                        {{weatcherCity}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import { mapGetters } from 'vuex';
    export default {
        name: "Oclick",
        data(){
            return {
                date: null,
                week_day: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
                yikeapi: {appid: '27925983', appsecret: 'ptAzzE9r', unescape: '1', vue:'1'},
                weatherTimer: null,
                weatcher_tem: ["icon-leng","icon-shushijia-06","icon-yanre"],
                weatche_wea: ["icon-xiaxue", "icon-dalei", "icon-shachenbao", "icon-xiawu", "icon-bingbao", "icon-duoyun", "icon-xiayu", "icon-yintian", "icon-qingtian"],
            }
        },
        computed: {
            ...mapGetters('weather', ['getRecord']),
            dateTime(){
                return dayjs(this.date).format("HH:mm:ss");
            },
            timeInterval(){
                return dayjs(this.date).hour() < 12 ? "AM" : "PM";
            },
            dateToday(){
                return dayjs(this.date).format("YYYY-MM-DD");
            },
            dateWeek(){
                let day = dayjs(this.date).day();
                return this.week_day[day];
            },
            weatcherWin(){
                let weather = this.getRecord;
                if(! weather) return "吹啥疯呢";
                return weather.win_speed + weather.win;
            },
            weaIcon(){
                let weather = this.getRecord;
                if(! weather) return "╯︿╰";
                let icon;
                switch(weather.wea_img){
                    case "xue":
                        icon = this.weatche_wea[0];
                        break;
                    case "lei":
                        icon = this.weatche_wea[1];
                        break;
                    case "shachen":
                        icon = this.weatche_wea[2];
                        break;
                    case "wu":
                        icon = this.weatche_wea[3];
                        break;
                    case "bingbao":
                        icon = this.weatche_wea[4];
                        break;
                    case "yun":
                        icon = this.weatche_wea[5];
                        break;
                    case "yu":
                        icon = this.weatche_wea[6];
                        break;
                    case "yin":
                        icon = this.weatche_wea[7];
                        break;
                    case "qing":
                        icon = this.weatche_wea[8];
                        break;
                }
                return icon;
            },
            weaClass(){
                let weather = this.getRecord;
                if(! weather) return null;
                let iconClass;
                switch(weather.wea_img){
                    case "xue":
                        iconClass = "wea-xue";
                        break;
                    case "lei":
                        iconClass = "wea-lei";
                        break;
                    case "shachen":
                        iconClass = "wea-shachen";
                        break;
                    case "wu":
                        iconClass = "wea-wu";
                        break;
                    case "bingbao":
                        iconClass = "wea-bingbao";
                        break;
                    case "yun":
                        iconClass = "wea-yun";
                        break;
                    case "yu":
                        iconClass = "wea-yu";
                        break;
                    case "yin":
                        iconClass = "wea-yin";
                        break;
                    case "qing":
                        iconClass = "wea-qing";
                        break;
                }
                return iconClass;
            },
            weatcherTemperature(){
                let weather = this.getRecord;
                if(! weather) return "╯︿╰";
                return weather.tem + "℃";
            },
            temClass(){
                let weather = this.getRecord;
                if(! weather) return null;
                let tem = parseInt(weather.tem);
                if(tem < 16) return "tem-cold";
                if(tem < 24) return "tem-comfortable";
                return "tem-hot";
            },
            temIcon(){
                let weather = this.getRecord;
                if(! weather) return null;
                let tem = parseInt(weather.tem);
                if(tem < 16) return this.weatcher_tem[0];
                if(tem < 24) return this.weatcher_tem[1];
                return this.weatcher_tem[2];
            },
            weatcherHumidity(){
                let weather = this.getRecord;
                if(! weather) return null;
                return weather.humidity;
            },
            humidityClass(){
                let weather = this.getRecord;
                if(! weather || ! this.date) return null;
                let month = Number(dayjs(this.date).month());
                let quarter = 4 < month < 10 ? 1 : 0;
                let humidity = parseInt(weather.humidity);
                if(quarter){
                    if(humidity < 40) return "humidity-low";
                    if(humidity < 80) return "humidity-secondary";
                    return "humidity-high"
                }
                if(humidity < 30) return "humidity-low";
                if(humidity < 60) return "humidity-secondary";
                return "humidity-high"
            },
            weatcherCity(){
                let weather = this.getRecord;
                if(! weather) return "失联";
                return weather.city;
            }
        },
        mounted(){
            setInterval( ()=> {
                this.date = new Date();
            },1000)
        },
        beforeDestroy() {
            if(this.weatherTimer){
                clearInterval(this.weatherTimer);
            }
        }
    }
</script>

<style scoped>
    .clock {
        padding-top: .8em;
        padding-left: 4%;
        padding-right: 4%;
        padding-bottom: .8em;
        width: 92%;
        text-align: center;
        box-shadow: 0 10px 10px rgba(250, 250, 250, .12), 0 0 10px rgba(250, 250, 250, .24);
    }
    .clock-dial{
        padding-top: 2.5%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 2.5%;
        text-align: center;
        height: 95%;
        width: 95%;
        border-radius: 5px;
        box-shadow: 0 0 8px 8px rgba(232,237,250,0.6), 0 2px 8px 8px rgba(232,237,250,0.5);
    }
    .dial-within {
        padding-top: .6em;
        padding-bottom: .6em;
        width: 100%;
        border-radius: 3px;
    }
    .dial-weather, .dial-data {
        height: 1.8em;
    }
    .weather-win, .weather-wea, .weather-tem, .weather-humidity, .data-day, .data-week, .data-city {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
        height: 100%;
        line-height: 1.4;
        font-size: 1.08em;
        font-weight: 200;
        font-family: "华文隶书";
        float: left;
    }

    .weather-win {
        font-family: "华文行楷";
        justify-content: left;
        margin-left: 5%;
        width: 33%;
    }

    .weather-wea {
        justify-content: center;
        width: 15%;
    }
    .weather-wea .iconfont {
        margin-top: -4px;
    }
    .wea-xue {
        color: rgba(200, 200, 255, .8);
    }
    .wea-lei {
        color: rgba(73, 147, 255, .8);
    }
    .wea-shachen {
        color: rgba(255, 255, 172, .8);
    }
    .wea-wu {
        color: rgba(0, 0, 0, .8);
    }
    .wea-bingbao {
        color: rgba(73, 147, 255, .8);
    }
    .wea-yun {
        color: rgba(0, 0, 0, .8);
    }
    .wea-yu {
        color: rgba(73, 147, 255, .8);
    }
    .wea-yin {
        color: rgba(255, 255, 172, .8);
    }
    .wea-qing {
        color: rgba(255, 100, 100, .8);
    }
    .weather-wea .iconfont {
        font-weight: 1000;
    }

    .weather-tem {
        justify-content: center;
        width: 20%;
    }
    .tem-cold {
        color: rgba(137, 196, 255, .8);
    }
    .tem-comfortable {
        color: rgba(255, 180, 180, .8);
    }
    .tem-hot {
        color: rgba(255, 40, 40, .8);
    }
    .weather-tem .iconfont {
        margin-right: -.3em;
    }

    .weather-humidity {
        justify-content: right;
        width: 22%;
        margin-right: 5%;
    }
    .humidity-low {
        color: rgba(150, 150, 255, .8);
    }
    .humidity-secondary {
        color: rgba(100, 100, 255, .8);
    }
    .humidity-high {
        color: rgba(0, 0, 255, .8);
    }
    .weather-humidity .iconfont {
        margin-right: -.1em;
    }


    .data-day {
        margin-left: 5%;
        width: 39%;
        justify-content: left;
    }
    .data-week {
        font-family: "华文行楷";
        width: 23%;
        justify-content: center;
    }
    .data-city {
        font-family: "华文行楷";
        width: 27%;
        margin-right: 5%;
        justify-content: right;
    }
    .data-city .iconfont {
        font-size: 14px;
        margin-right: 2px;
        margin-top: -1px;
    }

    .dial-date {
        height: 4.2em;
    }
    .date-time {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
        justify-content: center;
        font-family: "华文隶书";
        font-size: 2.85em;
        font-weight: 1000;
        margin-left: .25em;
        width: 90%;
        height: 100%;
        color: rgba(0, 0, 0, .8);
    }
    .time-space {
        font-size: .6em;
        margin-top: .32em;
        margin-left: .1em;
        color: rgba(41, 40, 42, .5);
    }
    .time-interval {
        font-size: .28em;
        font-family: "华文仿宋";
        margin-top: 1.1em;
        margin-left: .15em;
    }
</style>