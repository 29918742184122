<template>
    <div>
        <div class="aside-card"><card /></div>
        <div class="aside-clock"><clock/></div>
        <div class="aside-catalogue" v-if="catalogue && catalogue.length"><Catalogue :catalogue="catalogue"/></div>
        <div class="aside-recent-post"><RecentPost/></div>
        <div class="aside-witticism"><Witticism :witticism="witticism"/></div>
    </div>
</template>

<script>
    import Card from '@/components/aside/Card.vue';
    import Clock from '@/components/aside/Clock.vue';
    import Catalogue from '@/components/aside/Catalogue.vue';
    import RecentPost from '@/components/aside/RecentPost.vue';
    import Witticism from '@/components/aside/Witticism.vue';
    export default {
        name: "AsideArticle",
        components: {Card, Clock, Catalogue, RecentPost, Witticism},
        props: ["catalogue", "witticism"]
    }
</script>

<style scoped>
    .aside-card, .aside-clock, .aside-catalogue, .aside-recent-post, .aside-witticism {
        width: 100%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .aside-card {
        margin-bottom: 1em;
    }
    .aside-clock, .aside-recent-post, .aside-catalogue {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .aside-witticism {
        margin-top: 1em;
    }
</style>