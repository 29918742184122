
import dayjs from 'dayjs';
import {dateValid} from '@/Tools';

// 文章信息相关配置
export default {

    namespaced: true,

    state: {
        lists: null
    },

    getters: {

        getQuantity: state => {
            if(! state.lists || ! state.lists.length) return;
            return state.lists.length;
        },

        getAmount: state => {
            if(! state.lists || ! state.lists.length) return;
            return Math.ceil(state.lists.length / 4);
        },

        getPage: state => {
            return (page) => {
                if (!state.lists) return;
              
                const maxPage = Math.ceil(state.lists.length / 4);
                if (page > maxPage) return [];
              
                const [recommend, routine] = state.lists.reduce(
                  (acc, item) => {
                    item.recommend ? acc[0].push(item) : acc[1].push(item);
                    return acc;
                  },
                  [[], []]
                );
              
                recommend.sort((a, b) => b - a);
                const merged = [...recommend, ...routine];
                const pageArticle = merged.slice(page * 4 - 4, page * 4);
              
                return pageArticle;
              }
        },

        getRecent: state => {
            if(! state.lists || ! state.lists.length) return;
            if(state.lists.length >= 4) return state.lists.slice(0, 4);
            return state.lists;
        },

        getClassifys: state => {
            if (!state.lists?.length) return;

            const result = new Map();
            state.lists.forEach((item) => {
            if (item.attribute.classify) {
                const key = item.attribute.classify;
                result.has(key) ? result.set(key, result.get(key) + 1) : result.set(key, 1);
            }
            });

            return Object.fromEntries(result);
        },

        getTags: state => {
            if (!state.lists?.length) return;

            const result = new Map();
            for (const item of state.lists) {
            const tags = item.attribute.tags;
            if (tags && tags.length) {
                for (const tag of tags) {
                result.has(tag) ? result.set(tag, result.get(tag) + 1) : result.set(tag, 1);
                }
            }
            }

            return Object.fromEntries(result);
        },

        getArchive: state => {
            if (!state.lists?.length) return;

            const result = new Map();
            state.lists.forEach((item) => {
            const year = dateValid(item.dynamic.push) ? dayjs(item.dynamic.push.toString().length === 10 ? item.dynamic.push*1000 : item.dynamic.push).year() : dayjs().year();
            if (result.has(year)) {
                result.get(year).push(item);
            } else {
                result.set(year, [item]);
            }
            });

            return result;
        },

        getClassifyArchive: state => {
            return (val) => {
                if (!state.lists?.length) return;
              
                const results = state.lists.filter((item) => item.attribute.classify.includes(val));
                if (!results.length) return new Map();
              
                const result = new Map();
                results.forEach((item) => {
                  const year = dateValid(item.dynamic.push) ? dayjs(item.dynamic.push.toString().length === 10 ? item.dynamic.push*1000 : item.dynamic.push).year() : dayjs().year();
                  result.has(year) ? result.get(year).push(item) : result.set(year, [item]);
                });
              
                return Object.fromEntries(result);
            }
        },

        getTagsArchive: state => {
            return (val) => {
                if (!state.lists?.length) return;
                
                const results = state.lists.filter((item) => {
                    const tags = item.attribute.tags;
                    return tags && tags.some((tag) => tag.indexOf(val) !== -1);
                });
                
                if (!results.length) return new Map();
                
                const result = new Map();
                results.forEach((item) => {
                    const year = dateValid(item.dynamic.push) ? dayjs(item.dynamic.push.toString().length === 10 ? item.dynamic.push*1000 : item.dynamic.push).year() : dayjs().year();
                    result.has(year) ? result.get(year).push(item) : result.set(year, [item]);
                });
                
                return Object.fromEntries(result);
            }
        },

        getArticle: state => {
            return (title) => {
                if (!state.lists?.length) return;
                
                return state.lists.find((item) => item.title === title);
            }
        },

        isArticleExist: state => {
            return (val) => {
                if (!state.lists?.length) return;
                
                return state.lists.some((item) => item.title === val);
            }
        },

        getAllArticle: state => {
            if(! state.lists || ! state.lists.length) return;
            return state.lists;
        },

        getSiteArticle: state => {
            return (val) => {
                if (!state.lists?.length) return;
                
                for (let i = 0; i < state.lists.length; i++) {
                    if (state.lists[i].title === val) {
                    return { site: false, article: state.lists[i] };
                    }
                }
                
                return { site: true, article: state.lists[0] };
            };
        },

        getRelatedArticle: state => {
            return (title, tags) => {
                if (!title || !tags || !tags.length) return;
                if (!state.lists || state.lists.length < 2) return;
                
                const tagSet = new Set(tags);
                return state.lists.some((item) => {
                    return item.title !== title && Array.from(tagSet).some((tag) => item.attribute.tags.includes(tag));
                });
            };
        },

        search: state => {
            return (title, tags) => {
                if (!title || !tags || !tags.length) return;
                if (!state.lists || state.lists.length < 2) return;
                
                const tagSet = new Set(tags);
                return state.lists.some((item) => {
                    return item.title !== title && tagSet.some((tag) => item.attribute.tags.includes(tag));
                });
            };
        }
    }
}