

// 妙语相关配置
export default {

    namespaced: true,

    mutations: {
        POETRY_INIT(state, val){
            state.poetry = val;
        },
        ESSAY_INIT(state, val){
            state.essay = val;
        }
    },

    state: {
        poetry: null,
        essay: null
    },

    getters: {

        poetryRandom: state=> {
            return (which) => {
                if (!state.poetry) return;
                
                const [label, poetries] = Object.entries(state.poetry)[Math.floor(Math.random() * Object.entries(state.poetry).length)];
                return poetries[Math.floor(Math.random() * poetries.length)];
            };
        },
        poetryAppoint: state=> {
            return (which) => {
                if (!state.poetry) return;
                
                const labels = Object.keys(state.poetry);
                const label = which && labels.includes(which) ? which : labels[Math.floor(Math.random() * labels.length)];
                const poetries = state.poetry[label];
                
                return poetries[Math.floor(Math.random() * poetries.length)];
            };
        },

        essayRandom: state=> {
            return (which) => {
                if (!state.essay) return;
                
                const [label, essaies] = Object.entries(state.essay)[Math.floor(Math.random() * Object.entries(state.essay).length)];
                return essaies[Math.floor(Math.random() * essaies.length)];
            };
        },
        essayAppoint: state=> {
            return (which) => {
                if (!state.essay) return;
                
                const labels = Object.keys(state.essay);
                const label = which && labels.includes(which) ? which : labels[Math.floor(Math.random() * labels.length)];
                const poetries = state.essay[label];
                
                return poetries[Math.floor(Math.random() * poetries.length)];
            };
        },
    }
}