<template>
    <div id="article">
        <div id="page-bg"><Background :background="backdrop ? backdrop : background" /></div>
        <div id="site-header"><ArticleHeader :articleHeader="articleHeader"/></div>
        <div id="layout-content">
            <div class="layout">
                <div class="article-contents">
                    <ArticleContent :title="title" :uuid="uuid" :ciphertext="ciphertext" :articleContent="articleContent"
                    :articleContentSignature="articleContentSignature" :articleContentRelation="articleContentRelation" :articleContentComment="articleContentComment"/>
                </div>
                <div class="aside-contents"><AsideArticle :catalogue="catalogue" :witticism="witticism"/></div>
            </div>
        </div>
    </div>
</template>


<script>
    import ClipboardJS from 'clipboard';
    import Prism from 'prismjs';
    import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
    import 'prismjs/plugins/line-numbers/prism-line-numbers';
    import 'prismjs/plugins/toolbar/prism-toolbar.css';
    import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
    import 'prismjs/themes/prism-tomorrow.css';
    import {marked} from 'marked';
    import {nanoid} from 'nanoid';
    import {initialApi, articleApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    import AsideArticle from '@/components/aside/AsideArticle.vue';
    import ArticleContent from '@/components/pages/article/Article.vue';
    import ArticleHeader from '@/components/pages/article/ArticleHeader.vue';
    export default {
        name: "Article",
        components: {Background, ArticleHeader, ArticleContent, AsideArticle},
        props: ['title', 'BackTop'],
        data(){
            return{
                uuid: null,
                backdrop: null,
                background: null,
                catalogue: null,
                witticism: null,
                articleHeader: {
                    title: null,
                    address: null,
                    head: null,
                    name: null,
                    views: 0,
                    push: null,
                    update: null
                },
                articleContent: {
                    label: null,
                    content: null,
                    tags: null,
                    verification: null
                },
                articleCatalogue: null,
                articleContentSignature: {
                    author: null,
                    push: null
                },
                articleContentRelation: {
                    title: null,
                    tags: null
                },
                articleContentComment: null,
                articleContentAttached: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            visitAttached(article, resolve) {
                if(this.articleContentAttached){
                    resolve();
                    return;
                }
                articleApi.visitAttached(article=article).then(
                    response => {
                        if(response.status === 200){
                            response.data.forEach( (item)=> {
                                if(! this.articleContentAttached) this.articleContentAttached = new Array();
                                this.articleContentAttached.push(item);
                            })
                        }else{
                            this.$notify.error({title: '错误', message: response.data});
                        }
                        resolve();
                    },
                    error => {
                        //this.$notify.error({title: '错误', message: '发生未知错误，正在抢修中…'});
                        resolve();
                    }
                )
            },
            attachedSearch(rename){
                if(! this.articleContentAttached || this.articleContentAttached.length < 1 || ! rename) return;
                let result;
                for(let i=0;i<this.articleContentAttached.length;i++){
                    if(rename == this.articleContentAttached[i].rename){
                        result = this.articleContentAttached[i];
                        break;
                    }
                }
                return result;
            },
            sizeFomat(size){
                if(! size) return "文件大小异常";
                if(size < 1024) return size.replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + "&nbsp;&nbsp;Byte";
                if(size < 1024 * 1024) return (size / 1024).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + "&nbsp;&nbsp;KB";
                if(size < 1024 * 1024 * 1024) return (size / 1024 / 1024).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + "&nbsp;&nbsp;MB";
                return (size / 1024 / 1024 / 1024).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + "&nbsp;&nbsp;GB";
            },
            catalogueInitial(){
                if(! this.articleCatalogue || ! this.articleCatalogue.length){
                    this.catalogue = null;
                    return;
                }

                let catalogues = {};
                this.articleCatalogue.forEach( (item, index)=> {
                    let obj = new Object();
                    obj.serial = index;
                    obj.val = item;
                    obj.children = [];
                    if(catalogues[item.level]){
                        catalogues[item.level].push(obj);
                    }else{
                        catalogues[item.level] = new Array();
                        catalogues[item.level].push(obj);
                    }
                })

                let keys = Object.keys(catalogues);
                if(! keys || ! keys.length) return;
                let results = catalogues[keys[0]];
                for(let i=0;i<=results.length;i++){
                    if(i == 0) continue;
                    if(i == results.length){
                        let quantity = this.articleCatalogue.length - results[i-1].serial - 1;
                        if(quantity != 0){
                            results[i-1].children = this.articleCatalogue.slice(results[i-1].serial + 1, this.articleCatalogue.length);
                        }
                    }else{
                        let quantity = results[i].serial - results[i-1].serial - 1;
                        if(quantity != 0){
                            results[i-1].children = this.articleCatalogue.slice(results[i-1].serial + 1,results[i].serial);
                        }
                    }
                }
                this.catalogue = results;
            },
            attachedFormat(){
                this.articleCatalogue = new Array();
                let newString = this.articleContent.content.replace(/(\[(.[^\]]+)\]\((.[^)]+)\))/g, (rs, $1, $2, $3) => {
                    if($2 == "attached"){
                        let att = this.attachedSearch($3);
                        if(att){
                            let newSize = this.sizeFomat(att.size);
                            return `[${att.rename}](${att.route} "${newSize}")`;
                        }else{
                            return `[数据异常](/ "访问内部环境")`;
                        }
                    }
                    if($3.indexOf("chamas.cn")==-1) return `[${$2}](${$3} "注意访问外部环境")`;
                    return `[${$2}](${$3} "访问内部环境")`;
                })
                this.articleContent.content = marked(newString);
                this.catalogueInitial();
            },
            ciphertext(uuid, encryption){
                if(! encryption){
                    this.$notify.error({title: '错误', message: '请输入正确密码哦'});
                    return;
                }
                this.loadingShow();
                articleApi.getConnet(uuid=uuid, encryption=encryption).then(
                    response=> {
                        if (response.status !== 200){
                            this.$notify.error({title: '错误', message: '请输入正确密码哦'});
                            setTimeout( ()=> {
                                this.loadingHide();
                            }, 500)
                            return;
                        }
                        Promise.all([
                            new Promise( (resolve)=> {
                                this.articleContent.content = response.data;
                                resolve();
                            }),
                            new Promise( (resolve)=> {
                                this.visitAttached(article, resolve);
                            })
                        ]).then( (res)=> {
                            this.attachedFormat();
                            this.BackTop();
                            this.articleContent.verification = false;
                            setTimeout( ()=> {
                                this.loadingHide();
                            }, 500)
                        })
                    },
                    error => {
                        this.$notify.error({title: '错误', message: '请输入正确密码哦'});
                        setTimeout( ()=> {
                            this.loadingHide();
                        }, 500)
                    }
                )
            },
            copyCode(id) {
                const codeBlock = document.getElementById(id);
                if (codeBlock) {
                    const textArea = document.createElement('textarea');
                    textArea.value = codeBlock.innerText;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                }
            }
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom', 'portraitAll']),
            ...mapGetters('witticism', ['poetryRandom', 'essayRandom']),
            ...mapGetters('article', ['getArticle', 'isArticleExist']),
            ...mapGetters('author', ['searchAuthor'])
        },
        watch: {
            "title": {
                immediate: true,
                handler(val, old){
                    this.loadingShow();
                    this.catalogue = null;
                    if(this.isArticleExist(val)){
                        let article = this.getArticle(this.title);
                        Promise.all([
                            new Promise( (resolve)=> {  
                                this.uuid = article.uuid;
                                let author = this.searchAuthor(article.author);
                                this.articleContent.content= null;
                                this.articleContent.comments= null;
                                this.backdrop = article.background;

                                this.articleContent.label = article.attribute.label;
                                this.articleContent.tags = article.attribute.tags;

                                this.articleHeader.title = article.title;
                                this.articleHeader.name = author.name;
                                this.articleHeader.head = author.head;
                                this.articleHeader.address = author.address;
                                this.articleHeader.views = article.dynamic.views;
                                this.articleHeader.push = article.dynamic.push;
                                this.articleHeader.update = article.dynamic.update;

                                this.articleContentSignature.author = author.name;
                                this.articleContentSignature.push = article.dynamic.push;

                                this.articleContentRelation.title = article.title;
                                this.articleContentRelation.tags = article.attribute.tags;
                                resolve();
                            }),
                            
                            new Promise( (resolve)=> {
                                let background = this.wallpaperRandom();
                                if(background){
                                    this.background = background;
                                    resolve();
                                    return;
                                }
                                initialApi.random_wallpaper().then(
                                    response => {
                                        if(response.status === 200){
                                            this.background = response.data.route;
                                        }
                                        resolve();
                                    },
                                    error => {
                                        this.$notify.error({title: '错误', message: error});
                                        resolve();
                                    }
                                )
                            }),
                            new Promise( (resolve)=> {
                                let essay = this.essayRandom();
                                if(essay){
                                    resolve(essay);
                                    return;
                                }
                                initialApi.random_essay().then(
                                    response => {
                                        let _essay;
                                        if(response.status === 200){
                                            _essay = response.data;
                                        }
                                        resolve(_essay ? _essay : null);
                                    },
                                    error => {
                                        this.$notify.error({title: '错误', message: error});
                                        resolve();
                                    }
                                )
                            })
                        ]).then( (res)=> {
                            if(article.encryption == 2){
                                this.BackTop();
                                this.articleContent.verification = true;
                                setTimeout( ()=> {
                                    this.loadingHide();
                                }, 500)
                            }else{
                                Promise.all([
                                    new Promise( (resolve)=> {
                                        articleApi.getConnet(article.uuid).then(
                                            response => {
                                                if(response.status === 200){
                                                    this.articleContent.content = response.data;
                                                }else{
                                                    this.$notify.error({title: '错误', message: '文章内容加载失败'});
                                                }
                                                resolve();
                                            },
                                            error => {
                                                this.$notify.error({title: '错误', message: error});
                                                resolve();
                                            }
                                        )
                                    }),
                                    new Promise( (resolve)=> {
                                        this.visitAttached(article.uuid, resolve);
                                    })
                                ]).then( (res)=> {
                                    this.attachedFormat();
                                    this.BackTop();
                                    this.articleContent.verification = false;
                                    setTimeout( ()=> {
                                        this.loadingHide();
                                    }, 500)
                                })
                            }
                        })
                    }else{
                        this.$router.push("/404/");
                    }
                }
            }
        },
        mounted(){
            const vue = this
            this.clipboard = new ClipboardJS('.code-copy');
            this.clipboard.on('success', function(e) {
                console.log('Text copied to clipboard:', e.text);
                e.clearSelection();
                setTimeout( ()=> {
                    vue.$notify.success({title: '成功', message: '已复制'});
                }, 150)
                
            });
            this.clipboard.on('error', function(e) {
                console.error('Copy to clipboard failed:', e.action);
                setTimeout( ()=> {
                    vue.$notify.error({title: '失败', message: '遇到硬茬啦'});
                }, 200)
            });

            let renderer = new marked.Renderer();
            renderer.heading = (text, level, raw)=> {
                let obj = new Object();
                if(raw.indexOf("<") !== -1 && raw.indexOf(">") !== -1){
                    raw = raw.slice(raw.indexOf(">") + 1, raw.lastIndexOf("<"));
                }
                obj.title = raw;
                obj.level = level;
                obj.id = nanoid();
                this.articleCatalogue.push(obj);
                return "<h"+ level +" id='"+ obj.id +"'>"+ text +"</h"+ level +">";
            }
            renderer.link = (href,title,text)=> {
                return '<a href="'+ href +'" title="'+ title +'" target="_blank">'+ text +'</a>';
            }
            renderer.code = (code, language) => {
                const codeId = nanoid();
                const langClass = language ? `language-${language}` : 'language-javascript';
                const highlightedCode = Prism.highlight(code, Prism.languages[language] || Prism.languages.javascript, language);

                return `
                    <div class="code-block">
                        <div class="code-header">
                            <span class="code-language">${language || 'code'}</span>
                            <button class="code-copy" data-clipboard-target="#code-${codeId}">复制代码</button>
                        </div>
                        <pre id="code-${codeId}" class="line-numbers ${langClass}"><code class="${langClass}">${highlightedCode}</code></pre>
                    </div>`;
            };

            marked.setOptions({
                renderer,
                gfm: true,
                tables: true,
                breaks: true,
                pedantic: false,
                sanitize: false,
                smartLists: true,
                smartypants: false,
            });
        }
    }
</script>


<style scoped>
    #article {
        width: 100%;
    }
    #site-header, #layout-content {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
        width: 100%;
    }
    #site-header {
        padding-top: 16em;
        padding-bottom: 4em;
    }
</style>

<style>
    .layout {
        padding-top: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2.5em;
        width: 100%;
        max-width: 1200px;
    }
    .article-contents {
        width: 76%;
        float: left;
    }
    .aside-contents {
        width: 23%;
        float: right;
        position: sticky;
        top: -29.5em;
    }

    .code-block {
        position: relative;
        margin: 1rem 0;
        background-color: #2d2d2d;
        border-radius: 0.3rem;
        overflow: hidden;
    }

    .code-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #444;
        padding: 0.5rem;
        border-radius: 0.3rem 0.3rem 0 0;
        border-bottom: 1px solid #333;
    }

    .code-language {
        font-weight: bold;
        color: #ffcc00;
    }

    .code-copy {
        background: #444;
        border: none;
        color: #fff;
        padding: 0.3rem;
        border-radius: 0.2rem;
        cursor: pointer;
        margin-left: 0.5rem;
        font-size: 0.9rem;
    }

    pre {
        margin: 0;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.5rem;
        background: #2d2d2d !important;
        border-radius: 0 0 0.3rem 0.3rem;
        overflow: auto;
        margin-bottom: 0.5rem !important;
    }

    .line-numbers {
        padding-left: 0.8rem !important;
    }

    .article-image {
        max-width: 100%;
        cursor: pointer;
        border: 1px solid #ddd;
        padding: 5px;
        border-radius: 4px;
    }
</style>