<template>
    <div class="header">
        <div class="header-title">
            <div class="title-content">
                <div class="title-span"><router-link  to="/">{{getTitle}}</router-link></div>
            </div>
            <div class="content-nav">
                <div class="nav-item">
                    <router-link  to="/"><i class="iconfont icon-home i-first"></i>首页</router-link>
                </div>
                <div class="nav-item">
                    <el-col :span="24">
                        <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            <i class="iconfont icon-folder-open i-first"></i>
                            文章归档
                            <i class="el-icon-arrow-down el-icon--right i-second"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <router-link  to="/classify/"><el-dropdown-item icon="iconfont icon-navicon-chfl">分类</el-dropdown-item></router-link>
                            <router-link  to="/tags/"><el-dropdown-item icon="iconfont icon-collection-tag">标签</el-dropdown-item></router-link>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </div>
                <div class="nav-item">
                    <el-col :span="24">
                        <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            <i class="iconfont icon-duomeiti- i-first"></i>
                            多媒体
                            <i class="el-icon-arrow-down el-icon--right i-second"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <router-link  to="/music/"><el-dropdown-item icon="iconfont icon-yinlemusic214">音乐</el-dropdown-item></router-link>
                            <router-link  to="/gallery/"><el-dropdown-item icon="iconfont icon-image-outline">图册</el-dropdown-item></router-link>
                            <router-link  to="/video/"><el-dropdown-item icon="iconfont icon-airplayvideo">视频</el-dropdown-item></router-link>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </div>
                <!-- <div class="nav-item">
                    <router-link  to="/tools/"><i class="iconfont icon-diannao i-first"></i>百宝箱</router-link>
                </div> -->
                <div class="nav-item">
                    <router-link  to="/about/"><i class="iconfont icon-about i-first"></i>关于我</router-link>
                </div>
                <div class="nav-item">
                    <div class="search-input">
                        <el-input size="small" placeholder="文章搜索…" v-model.trim="local_search_val" @keyup.enter.native="localSearch">
                            <el-button slot="append" icon="el-icon-search" @click="localSearch"></el-button>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapMutations, mapGetters} from 'vuex';
    export default {
        name: "AppHeader",
        data() {
            return {
                local_search_val: ""
            }
        },
        methods: {
            ...mapMutations('popup', {searchShow: 'SEARCH_SHOW', searchEdit: 'SEARCH_EDIT'}),
            localSearch(){
                if(this.local_search_val){
                    this.searchEdit(this.local_search_val);
                    this.local_search_val = "";
                }
                this.searchShow();
            }
        },
        computed: {
            ...mapGetters('author', ['getTitle']),
        }
    }
</script>


<style>
    .header-hide {
        position: absolute;
        color: #eee;
    }
    .header-hide a, .header-hide .el-dropdown {
        color: #eee;
        text-decoration: none;
    }
    .header-show {
        position: fixed;
        top: 0;
        color: black;
        background-color: rgba(220,248,240,0.5);
    }
    .header-show a, .header-show .el-dropdown {
        color: black;
        text-decoration: none;
    }
</style>

<style scoped>
    .header {
        width: 100%;
        height: 100%;
    }
    .header-title {
        width: 92vw;
        height: 100%;
        margin-left: 6vw;
        margin-right: 2vw;
    }
    .title-content, .content-nav {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .title-content {
        padding-left: .1em;
        padding-right: .2em;
        height: 100%;
        float: left;
    }
    .title-content .title-span {
        font-family: "华文隶书";
        font-size: 1.68em;
        font-weight: 1000;
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 15%);
    }

    .content-nav {
        width: auto;
        height: 100%;
        font-family: "华文楷体";
        font-weight: 350;
        float: right;
        display: flex;
        justify-content: space-around;
    }
    .nav-item {
        margin-left: .25em;
        margin-right: .25em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        text-shadow: 0.05rem 0.05rem 0.1rem rgb(0 0 0 / 30%);
        font-size: 1.18em;
    }
    .nav-item .el-dropdown {
        font-size: 100%;
        cursor: pointer;
    }
    .el-dropdown-menu {
        background: rgba(255, 255, 255, .8);
    }
    .el-dropdown-menu a {
        text-decoration: none;
    }
    .el-dropdown-menu a li {
        font-size: 1.14em;
        font-family: "华文楷体";
        font-weight: 800;
        text-shadow: 0.05rem 0.05rem 0.1rem rgb(0 0 0 / 30%);
    }
    .nav-item .i-second {
        margin-left: -5px;
    }

    .search-input {
        width: 8em;
    }
    .el-input--small {
        font-size: .68em;
        font-family: "华文行楷";
    }
</style>