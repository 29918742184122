<template>
    <div class="subject-aplayer" v-if="listAudio && music" :class="apalyer_show">
        <Aplayer
            mutex
            theme="white"
            :list="listAudio"
            :music="play_music"
            :volume="volume"
            :showLrc="show_lrc"
            :listFolded="list_folded"
            :listMaxHeight="`${listMaxHeight > 420 ? listMaxHeight : 420}px`"
        />
        <div class="lrc-logo" :class="lrc_class" @click="lrcClick" v-show="lrc_state">
            <i class="iconfont icon-cidianku"></i>
        </div>
        <div class="reveal-logo" @click="revealClick">
            <i class="iconfont" :class="reveal_logo"></i>
        </div>
    </div>
</template>


<script>
    import Aplayer from 'vue-aplayer';
    import {arrayShuffle} from '@/Tools';
    import {mapState, mapGetters} from 'vuex';
    Aplayer.disableVersionBadge = true;
    export default {
        name: "AplayerMini",
        components: {Aplayer},
        data(){
            return{
                volume: 1,
                play_music: null,
                show_lrc: true,
                list_folded: true,
                lrc_class: "lrc-reveal",
                lrc_state: false,
                aplayer_state: "aplayer-conceal",
                reveal_logo: "icon-youhua",
                apalyer_show: "mini-aplayer"
            }
        },
        methods: {
            lrcClick(){
                if(this.show_lrc){
                    this.show_lrc = false;
                    this.lrc_class = "lrc-hide";
                }else{
                    this.show_lrc = true;
                    this.lrc_class = "lrc-reveal";
                }
            },
            revealClick(){
                if(this.reveal_logo === "icon-youhua"){
                    this.apalyer_show = "intact-aplayer";
                    this.reveal_logo = "icon-zuohua";
                    this.lrc_state = true;
                    this.aplayer_state = "aplayer-show";
                }else{
                    this.apalyer_show = "mini-aplayer";
                    this.reveal_logo = "icon-youhua";
                    this.lrc_state = false;
                    this.aplayer_state = "aplayer-conceal";
                }
            }
        },
        computed: {
            ...mapGetters('media', ['musicAll', 'musicAppointLabel']),
            ...mapState('media', ['music']),
            listAudio(){
                let list = this.musicAll ? this.musicAll : [];
                if(! list || ! list.length) return;

                let results = new Array();
                list.forEach( (item)=> {
                    let obj = new Object();
                    obj.artist = item.artist;
                    obj.title = item.title;
                    obj.src = item.audio
                    obj.pic = item.cover;
                    obj.lrc = item.lyric;
                    results.push(obj);
                })
                results = arrayShuffle(results);
                this.play_music = results[0];
                return results;
            },
            listMaxHeight(){
                if(! this.listAudio || ! this.listAudio.length) return;
                let len = this.listAudio.length;
                return 32 + 33 * (len - 1);
            }
        },
        watch: {
            "apalyer_show": {
                immediate: true,
                handler(val, old){
                    if(val === "mini-aplayer"){
                        if(this.lrc_state) this.lrc_state = false;
                        if(this.aplayer_state) this.aplayer_state = false;
                    }
                }
            }
        }
    }
</script>


<style scoped>
    .subject-aplayer {
        position: static;
        width: 420px;
    }
    .lrc-logo {
        position: absolute;
        right: 5%;
        bottom: 15px;
        cursor: pointer;
    }
    .lrc-hide i {
        color: #ACACAC;
        cursor: pointer;
    }
    .lrc-reveal i {
        color: #000;
        cursor: pointer;
    }
    .reveal-logo {
        float: left;
        width: 5%;
        height: 65px;
        cursor: pointer;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        background: rgba(255, 255, 255, .85) !important;
    }
    .reveal-logo i {
        cursor: pointer;
        font-size: 25px !important;
        font-weight: 1000 !important;
    }
</style>

<style>
    #popup-aplayer .subject-aplayer .aplayer {
        float: left;
        margin: 0 !important;
        overflow: visible !important;
        position: static !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        background: rgba(255, 255, 255, .85) !important;
    }
    #popup-aplayer .mini-aplayer .aplayer {
        width: 0px !important;
    }
    #popup-aplayer .intact-aplayer .aplayer {
        width: 95% !important;
    }
    #popup-aplayer .intact-aplayer .aplayer-pic {
        display: block;
        width: 65px !important;
        height: 65px !important;
        opacity: .6 !important;
        background-color: rgba(255, 255, 255, .85);
    }
    #popup-aplayer .mini-aplayer .aplayer-pic {
        display: none;
        width: 0px !important;
        height: 0px !important;
    }
    #popup-aplayer .subject-aplayer .aplayer-body .aplayer-info {
        padding: 0px !important;
        height: 65px !important;
    }
    #popup-aplayer .subject-aplayer .aplayer .aplayer-body .aplayer-info .aplayer-music .aplayer-title {
        font-size: 20px !important;
        font-family: "华文楷体" !important;
    }
    #popup-aplayer .subject-aplayer .aplayer .aplayer-body .aplayer-info .aplayer-music .aplayer-author {
        font-size: 15px !important;
        font-family: "华文楷体" !important;
    }
    #popup-aplayer .subject-aplayer .aplayer-lrc::before {
        height: 0px !important;
    }
    #popup-aplayer .subject-aplayer .aplayer-lrc::after {
        height: 0px !important;
    }
    #popup-aplayer .subject-aplayer .aplayer .aplayer-body .aplayer-info .aplayer-lrc {
        display: block !important;
        position: fixed !important;
        left: 0em !important;
        right: 0em !important;
        margin: 0em !important;
        bottom: .5em !important;
        width: 100vw !important;
        pointer-events: none !important;
        text-shadow: -1px -1px 0 #fff !important;
    }
    #popup-aplayer .subject-aplayer .aplayer-lrc p {
        font-size: 15px !important;
        font-family: "华文楷体" !important;
    }
    #popup-aplayer .subject-aplayer .aplayer .aplayer-lrc .aplayer-lrc-contents .aplayer-lrc-current {
        font-family: "华文楷体" !important;
        font-weight: 1000 !important;
        color: red !important;
        font-size: 1.02em;
    }

    #popup-aplayer .subject-aplayer .aplayer-volume {
        background: rgba(0, 0, 255, .6) !important;
    }

    #popup-aplayer .subject-aplayer .aplayer.aplayer-withlist .aplayer-list {
        position: absolute !important;
        top: -350px !important;
        width: 420px !important;
        height: 350px !important;
        overflow-y: scroll !important;
        background-color: rgba(255, 255, 255, .85);
    }
    #popup-aplayer .subject-aplayer .aplayer .aplayer-list .aplayer-list-cur{
        background: rgba(0, 0, 255, .6) !important;
    }
    #popup-aplayer .intact-aplayer .aplayer.aplayer-withlist .aplayer-list {
        display: block;
    }
    #popup-aplayer .mini-aplayer .aplayer.aplayer-withlist .aplayer-list {
        display: none;
    }


    #popup-aplayer .subject-aplayer .aplayer-list ol li .aplayer-list-title {
        font-size: 1.28em !important;
        font-family: "华文楷体" !important;
        color: black !important;
    }

    #popup-aplayer .subject-aplayer .aplayer-list ol li .aplayer-list-author {
        font-size: 1.18em !important;
        font-family: "华文楷体" !important;
    }
</style>