
<template>
    <div id="index">
        <!-- background -->
        <div id="page-bg"><Background :background="background" /></div>
        <div id="full-page">
            <div id="site-info"><site-info :subtitle="subtitle" :jump="jump" :page="page ? page : 1"/></div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="post-contents">
                    <div class="post-item" v-for="(article, index) in getArticles" :key="index">
                        <Post :article="article" :author="searchAuthor(article.author)" />
                    </div>
                    <div class="post-pagination"><HomePagination :page="page ? page : 1"/></div>
                </div>
                <div class="aside-contents"><AsidePublic :witticism="witticism"/></div>
            </div>
        </div>
    </div>
</template>


<script>
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Post from '@/components/pages/home/Post.vue';
    import SiteInfo from '@/components/pages/home/SiteInfo.vue';
    import Background from '@/components/overall/Background.vue';
    import AsidePublic from '@/components/aside/AsidePublic.vue';
    import HomePagination from '@/components/pages/home/Pagination.vue';
    export default {
        name: "Home",
        props: ["page", "BackTop"],
        components: {Background, SiteInfo, Post, HomePagination, AsidePublic},
        data(){
            return {
                background: null,
                subtitle: null,
                witticism: null,
                custom: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),

            // 导航点击
            jump(){
                document.getElementById("layout-content").scrollIntoView();
            },
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom']),
            ...mapGetters('witticism', ['poetryRandom', 'essayRandom']),
            ...mapGetters('article', ['getAmount', 'getPage']),
            ...mapGetters('author', ['searchAuthor']),
            getArticles(){
                if(! this.custom) return;
                let arry = [];
                for(let i=0;i<this.custom.length;i++){
                    arry[i] = new Object();
                    arry[i] = this.custom[i];
                    if(i%2){
                        arry[i].site = "info";
                    }else{
                        arry[i].site = "cover";
                    }
                }
                return arry;
            }
        },
        watch: {
            "page": {
                immediate: true,
                handler(val){
                    this.loadingShow();
                    val = parseInt(val);
                    let path = this.$route.path;
                    if((isNaN(val) || val === 1) && path !== "/"){
                        this.$router.push("/");
                        return;
                    }
                    if(val > this.getAmount){
                        this.$router.push("/page/" + this.getAmount + "/");
                        return;
                    }
                    Promise.all([
                        new Promise( (resolve)=> {
                            this.custom = this.getPage(val ? val : 1);
                            resolve();
                        }),
                        new Promise( (resolve)=> {
                            let background = this.wallpaperRandom();
                            if(background){
                                this.background = background;
                                resolve();
                                return;
                            }
                            initialApi.random_wallpaper('').then(
                                response => {
                                    if(response.status === 200){
                                        this.background = response.data.route;
                                    }
                                    resolve();
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        }),
                        new Promise( (resolve)=> {
                            let poetry = this.poetryRandom();
                            if(poetry && poetry.content){
                                resolve(poetry.content);
                                return;
                            }
                            initialApi.random_poetry().then(
                                response => {
                                    let _poetry;
                                    if(response.status === 200){
                                        _poetry = response.data;
                                    }
                                    resolve(_poetry ? _poetry.content : null);
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        }),
                        new Promise( (resolve)=> {
                            let essay = this.essayRandom();
                            if(essay){
                                resolve(essay);
                                return;
                            }
                            initialApi.random_essay().then(
                                response => {
                                    let _essay;
                                    if(response.status === 200){
                                        _essay = response.data;
                                    }
                                    resolve(_essay ? _essay : null);
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        })
                    ]).then( (res)=> {
                        this.BackTop();
                        setTimeout( ()=> {
                            if(res[2]) this.subtitle = res[2];
                            if(res[3]) this.witticism = res[3];
                            this.loadingHide();
                        }, 500)
                    })
                }
            }
        }
    }
</script>


<style scoped>
    #index {
        width: 100%;
    }
    #full-page {
        width: 100%;
        height: 100vh;
    }
    #site-info, #layout-content {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    #site-info {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
    }
    #layout-content {
        position: relative;
        width: 100%;
    }
</style>

<style scoped>
    .layout {
        padding-top: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2.5em;
        width: 100%;
        max-width: 1200px;
    }
    .post-contents {
        width: 76%;
        float: left;
    }
    .post-item {
        width: 100%;
        margin-bottom: 1em;;
    }

    .post-pagination {
        width: 100%;
    }


    .aside-contents {
        width: 23%;
        float: right;
        position: -webkit-sticky;
        position: sticky;
        top: -31.5em;
    }
</style>