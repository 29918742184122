<template>
    <div id="image">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span" v-if="cover_show">图册</div>
            <div class="title-span" v-if="! cover_show && gallery_describe">{{gallery_describe}}</div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="image-contents">
                    <template v-if="cover_show">
                        <div class="image-item" v-for="(cover, index) in covers" :key="index" :class="imageClass(index)"
                        @mouseenter="describeLoad(index)" @mouseleave="describeHide(index)">
                            <router-link :to="`${cover.title}/`">
                                <div class="item-layer" v-show="cover_layer[index]"></div>
                                <div class="item-cover" :class="coverClass(cover.title)">
                                    <el-image :src="cover.cover">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="item-info">
                                    <div class="info-title">{{cover.title}}</div>
                                    <div class="info-interval" v-show="cover_layer[index]"></div>
                                    <div class="info-describe" v-show="cover_layer[index]">{{cover.describe}}</div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-if="! cover_show">
                        <GalleryContent :photograph="photograph"/>
                        <div class="photograph-pagination"><GalleryPagination :gallery="gallery" :page="page ? page : 1"/></div>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    import GalleryContent from '@/components/pages/gallery/Gallery.vue';
    import GalleryPagination from '@/components/pages/gallery/Pagination.vue';
    export default {
        name: "Gallery",
        components: {Background, GalleryContent, GalleryPagination},
        props: ['gallery', 'page', 'BackTop'],
        data(){
            return{
                page_loading: false,
                gallery_onload: false,
                gallery_loading: false,
                background: null,
                cover_layer: null,
                covers: null,
                cover_show: true,
                gallery_describe: null,
                photograph: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            ...mapMutations('atlas', {portraitInit: 'PORTRAIT_DATA_INIT', wallpaperInit: 'WALLPAPER_DATA_INIT'}),
            getCover(resolve, gallery){
                if(! this.covers || ! this.covers.length || (gallery != "portrait" && gallery != "wallpaper")){
                    resolve();
                    return;
                }
                let index, correspond = false;
                for(let i=0; i<this.covers.length; i++){
                    if(this.covers[i].gallery == gallery && ! this.covers[i].cover){
                        correspond = true;
                        index = i;
                        break;
                    }
                }
                let cover;
                switch(gallery){
                    case "portrait":
                        cover = this.portraitRandom();
                        break;
                    case "wallpaper":
                        cover = this.wallpaperRandom();
                        break;
                }

                if (index === undefined) { // 如果index未定义，提前退出
                    // this.$notify.error({ title: '错误', message: '封面设置出错了' });
                    resolve();
                    return;
                }

                if(cover){
                    this.covers[index].cover = cover;
                    correspond = false;
                }
                if(! correspond){
                    resolve();
                    return;
                }
                this.$notify.error({title: '错误', message: '封面设置出错了'});
                resolve();
            },
            imageClass(index){
                if(index == 0) return;
                if(index%2 != 0 && index >= 2) return "image-stratum image-right";
                if(index%2 != 0) return "image-right";
                if(index >= 2) return "image-stratum";
            },
            coverClass(title){
                if(title == "用户头像") return;
                return "cover-other";
            },
            describeLoad(index){
                this.$set(this.cover_layer, index, true);
            },
            describeHide(index){
                this.$set(this.cover_layer, index, false);
            }
        },
        computed: {
            ...mapGetters('atlas', ['getgallery', 'portraitRandom', 'wallpaperRandom', 'getDescribe', 'getAmount', 'getPage'])
        },
        watch: {
            "gallery": {
                immediate: true,
                handler(val, old){
                    this.gallery_loading = true;
                    this.covers = null;
                    this.photograph = null;
                    if(! val){
                        Promise.all([
                            new Promise( (resolve)=> {
                                let background = this.wallpaperRandom();
                                if(background){
                                    this.background = background;
                                    resolve();
                                    return;
                                }
                                initialApi.random_wallpaper('').then(
                                    response => {
                                        if(response.status === 200){
                                            this.background = response.data.route;
                                        }
                                        resolve();
                                    },
                                    error => {
                                        this.$notify.error({title: '错误', message: error});
                                        resolve();
                                    }
                                )
                            }),
                            
                            new Promise( (resolve)=> {
                                if(this.covers && this.cover_layer){
                                    resolve();
                                    return;
                                }
                                this.covers = new Array();
                                this.cover_layer = new Array();
                                let gallery = this.getgallery();
                                if(gallery && gallery.length){
                                    gallery.forEach( (item)=> {
                                        if(item.amount && item.amount > 0){
                                            this.covers.push(item);
                                            this.cover_layer.push(false);
                                        }
                                    })
                                }
                                resolve();
                            })
                        ]).then( ()=> {
                            Promise.all([
                                new Promise( (resolve)=> {
                                    this.getCover(resolve, "portrait");
                                }),
                                new Promise( (resolve)=> {
                                    this.getCover(resolve, "wallpaper");
                                }),
                                new Promise( (resolve)=> {
                                    this.getCover(resolve, "album");
                                })
                            ]).then( ()=> {
                                this.cover_show = true;
                                this.gallery_loading = false;
                            })
                        })
                    }else{
                        if(val != "用户头像" && val != "背景壁纸" && val != "艺术写真") this.$router.push("/404/");
                        Promise.all([
                            new Promise( (resolve)=> {
                                let background = this.wallpaperRandom();
                                if(background){
                                    this.background = background;
                                    resolve();
                                    return;
                                }
                                initialApi.random_wallpaper('').then(
                                    response => {
                                        if(response.status === 200){
                                            this.background = response.data.route;
                                        }
                                        resolve();
                                    },
                                    error => {
                                        this.$notify.error({title: '错误', message: error});
                                        resolve();
                                    }
                                )
                            }),
                            new Promise( (resolve)=> {
                                let page;
                                if(! page) page = 1;
                                page = parseInt(this.page);
                                if(isNaN(page)) page = 1;
                                let gallery;
                                switch(val){
                                    case "用户头像":
                                        gallery = "portrait";
                                        this.photograph = this.getPage("portrait", page);
                                        this.gallery_describe = this.getDescribe('portrait');
                                        break;
                                    case "背景壁纸":
                                        gallery = "wallpaper";
                                        this.photograph = this.getPage("wallpaper", page);
                                        this.gallery_describe = this.getDescribe('wallpaper');
                                        break;
                                    }
                                resolve();
                            })
                        ]).then( ()=> {
                            if(! this.photograph || this.photograph.length < 1){
                                this.$notify.error({title: "错误", message: "图册为空！"});
                                this.cover_show = true;
                                this.gallery_loading = false;
                            }
                            if(this.photograph == -1){
                                let gallery;
                                if(this.gallery == "用户头像") gallery = "portrait";
                                if(this.gallery == "背景壁纸") gallery = "wallpaper";
                                this.$router.push("/gallery/" + this.gallery + "/" + this.getAmount(gallery) + "/");
                            }
                            this.cover_show = false;
                            this.gallery_loading = false;
                        })
                    }
                }
            },
            "page": {
                immediate: true,
                handler(val, old){
                    this.page_loading = true;
                    this.gallery_describe = null;
                    this.photograph = null;
                    if(! this.gallery){
                        this.page_loading = false;
                        return;
                    }
                    if(this.gallery_onload){
                        this.page_loading = false;
                        return;
                    }
                    Promise.all([
                        new Promise( (resolve)=> {
                            let background = this.wallpaperRandom();
                            if(background){
                                this.background = background;
                                resolve();
                                return;
                            }
                            initialApi.random_wallpaper('').then(
                                response => {
                                    if(response.status === 200){
                                        this.background = response.data.route;
                                    }
                                    resolve();
                                },
                                error => {
                                    this.$notify.error({title: '错误', message: error});
                                    resolve();
                                }
                            )
                        }),
                        new Promise( (resolve)=> {
                            let page;
                            if(! page) page = 1;
                            page = parseInt(this.page);
                            if(isNaN(page)) page = 1;
                            let gallery;
                            switch(this.gallery){
                                case "用户头像":
                                    gallery = "portrait";
                                    this.photograph = this.getPage("portrait", page);
                                    break;
                                case "背景壁纸":
                                    gallery = "wallpaper";
                                    this.photograph = this.getPage("wallpaper", page);
                                    break;
                            }
                            resolve();
                        })
                    ]).then( ()=> {
                        if(! this.photograph || this.photograph.length < 1){
                            this.$notify.error({title: "错误", message: "图册为空！"});
                            this.cover_show = true;
                            this.page_loading = false;
                        }
                        if(this.photograph == -1){
                            let gallery;
                            if(this.gallery == "用户头像") gallery = "portrait";
                            if(this.gallery == "背景壁纸") gallery = "wallpaper";
                            this.$router.push("/gallery/" + this.gallery + "/" + this.getAmount(gallery) + "/");
                        }
                        this.cover_show = false;
                        this.page_loading = false;
                    })
                }
            },
            "page_loading": {
                handler(val){
                    if(val || this.gallery_loading){
                        this.loadingShow();
                        return;
                    }
                    this.BackTop();
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500);
                }
            },
            "gallery_loading": {
                handler(val){
                    if(val || this.page_loading){
                        this.loadingShow();
                        return;
                    }
                    this.BackTop();
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500);
                }
            },
        }
    }
</script>

<style scoped>
    #image {
        width: 100%;
    }
    #site-title, #layout-content {
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    #layout-content .layout {
        width: 100%;
        margin-top: 2.5em;
        max-width: 1200px;
        position: relative;
        border-radius: 4px;
        background-size: cover !important;
    }
    #layout-content:after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .layout .image-contents {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }

    .image-contents:after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
</style>

/** 图册封面 */
<style scoped>
    .image-item {
        float: left;
        width: 49.5%;
        height: 18em;
        border-radius: 8px;
        position: relative;
    }
    .image-right {
        margin-left: 1%;
    }

    .image-stratum {
        margin-top: .8em;
    }

    .item-layer {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 8px;
        pointer-events: none;
        background: rgba(0, 0, 0, .5);
    }
    .image-item .item-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    }
    .item-cover .el-image, .item-cover .el-image .el-image__inner {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .cover-other .el-image, .cover-other .el-image .el-image__inner {
        height: 100%;
    }

    .item-info {
        z-index: 2;
        width: 80%;
        padding-top: 4em;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 4em;
        position: absolute;
        pointer-events: none;
        color: rgba(255, 255, 255, .95);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .info-title {
        font-size: 2em;
        font-weight: 1000;
        margin-left: -.15em;
        font-family: "华文行楷";
    }
    .info-interval {
        width: 100%;
        height: 2px;
        margin-top: .25em;
        border-radius: 10%;
        margin-bottom: .1em;
        background: #fff;
    }
    .info-describe {
        font-weight: 500;
        font-size: 1.28em;
        margin-top: .25em;
        margin-left: -.1em;
        font-family: "楷体";
    }
    .photograph-pagination {
        width: 100%;
    }
</style>