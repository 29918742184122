

// 该文件用于创建Vuex中最为核心的store
import Vue from 'vue';
import Vuex from 'vuex';
import authorOptions from '@/store/Author';
import atlasOptions from '@/store/Atlas';
import WeatherOptions from '@/store/Weather';
import WitticismOptions from '@/store/Witticism';
import PopupOptions from '@/store/Popup';
import articleOptions from '@/store/Article';
import MediaOptions from '@/store/Media';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        author: authorOptions,
        atlas: atlasOptions,
        weather: WeatherOptions,
        witticism: WitticismOptions,
        article: articleOptions,
        media: MediaOptions,
        popup: PopupOptions
    }
});