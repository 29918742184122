import {request} from '@/Tools';

const site = "https://proce.chamas.com.cn";
// const site = "http://localhost:8888";

const initialApi = {

    stationmaster: ()=> {
        return request({url: site + '/gain_information?retry=true&' + Math.random(), method: 'get'});
    },
    information_about: ()=> {
        return request({url: site + '/gain_information_about?retry=true&' + Math.random(), method: 'get'});
    },
    article: ()=> {
        return request({url: site + '/gain_article?retry=true&' + Math.random(), method: 'get'});
    },
    author: ()=> {
        return request({url: site + '/gain_author?retry=true&' + Math.random(), method: 'get'});
    },
    poetry: ()=> {
        return request({url: site + '/gain_poetry?retry=true&' + Math.random(), method: 'get'});
    },
    random_poetry: ()=> {
        return request({url: site + '/gain_random_poetry?retry=true&' + Math.random(), method: 'get'});
    },
    essay: ()=> {
        return request({url: site + '/gain_essay?retry=true&' + Math.random(), method: 'get'});
    },
    random_essay: ()=> {
        return request({url: site + '/gain_random_essay?retry=true&' + Math.random(), method: 'get'});
    },
    avatar: (group = '默认图像')=> {
        return request({url: site + '/gain_avatar?retry=true&group=' + group + '&' + Math.random(), method: 'get'});
    },
    random_avatar: (group = '默认图像')=> {
        return request({url: site + '/gain_random_avatar?retry=true&group=' + group + '&' + Math.random(), method: 'get'});
    },
    wallpaper: (group = 'nature')=> {
        return request({url: site + '/gain_wallpaper?retry=true&group=' + group + '&' + Math.random(), method: 'get'});
    },
    random_wallpaper: (group = 'nature')=> {
        return request({url: site + '/gain_random_wallpaper?retry=true&group=' + group + '&' + Math.random(), method: 'get'});
    },
    music: (group = '人世如烟波浩渺之海')=> {
        return request({url: site + '/gain_music?retry=true&group=' + group + '&' + Math.random(), method: 'get'});
    },
    weather : ()=> {
        return request({url: 'https://v1.yiketianqi.com/free/day', params: {retry: true, appid: "27925983", appsecret: "ptAzzE9r", unescape: "1", vue:"1"}, method: 'get'});
    }
}

const articleApi = {
    getConnet: (uuid, encryption = 'None')=> {
        return request({url: site + '/gain_article_content?retry=true&uuid=' + uuid + '&encryption=' + encryption + '&' + Math.random(), method: 'get'});
    },
    visitAttached: (article)=> {
        return request({url: site + '/gain_attached?article=' + article + '&' + Math.random(), method: 'get'});
    }
}

const commentApi = {
    comment: (params)=> {
        return request({url: site + '/comment?retry=true&' + Math.random(), method: 'get', params});
    }
}

export {
    initialApi,
    articleApi
}