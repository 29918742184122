
<template>
    <div id="music">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">音乐</div>
        </div>
        <div id="layout-content" v-if="musicShow">
            <div class="layout" :style="`backgroundImage:url(${set_img})`">
                <div class="top-aplayer"></div>
                <div class="subject-aplayer">
                    <Aplayer
                        mutex
                        showLrc
                        listFolded
                        theme="white"
                        ref="aplayer"
                        :list="listAudio"
                        :music="music"
                        :volume="volume"
                        @playing="playArtist"
                        :listMaxHeight="`${listMaxHeight > 420 ? listMaxHeight : 420}px`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {initialApi} from '@/api';
    import Aplayer from 'vue-aplayer';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    Aplayer.disableVersionBadge = true;
    export default {
        name: "Music",
        components: {Background, Aplayer},
        props: ["which", 'BackTop'],
        data(){
            return {
                volume: 1,
                background: null,
                default_img: "https://music.chamas.com.cn/music.webp",
                set_img: "https://music.chamas.com.cn/music.webp",
                music: null,
                timer: null,
                artist: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            playArtist(){
                if(this.timer) clearInterval(this.timer);
                this.artist = this.$refs.aplayer.currentMusic.artist;
                // this.changeArtistImg(this.listPortray);
            },
            changeArtistImg(portraies){
                if(! portraies || ! portraies.length) {
                    this.set_img = this.default_img;
                    return;
                }
                let portray = portraies[Math.floor(Math.random() * portraies.length)];
                let img = new Image();
                img.src = portray.route;
                img.onload = ()=> {
                    this.set_img = portray.route;
                }
            }
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom', 'portrayAppoint']),
            ...mapGetters('media', ['musicAll', 'musicAppoint']),
            listAudio() {
                let list;
                if (!this.which) {
                    list = this.musicAll;
                } else {
                    const whichArray = this.which.split(/&|、/);
                    list = whichArray.flatMap((item) => {
                    if (item === '1' || item === '2') {
                        return this.musicAppoint(null, null, item, null);
                    } else if (item.includes('语')) {
                        const language = item.split(/|、/);
                        return this.musicAppoint(null, null, null, language);
                    } else {
                        const choice = item.split(/|、/);
                        return this.musicAppoint(choice, choice, null, null);
                    }
                    });
                }

                if (!list || !list.length) return;

                const results = list.map((item) => ({
                    artist: item.artist,
                    title: item.title,
                    src: item.audio,
                    pic: item.cover,
                    lrc: item.lyric,
                }));

                this.music = results[0];
                return results;
                },
            
            // listPortray() {
            //     if (!this.artist) return;

            //     return this.portrayAppoint(this.artist.split(/[、|&]/));
            // },
            musicShow(){
                if(! this.listAudio || ! this.listAudio.length) return false;
                if(! this.music) return false;
                return true;
            },
            listMaxHeight(){
                if(! this.listAudio || ! this.listAudio.length) return;
                let len = this.listAudio.length;
                return 32 + 33 * (len - 1);
            }
        },
        watch: {
            // "artist": {
            //     handler(val){
            //         if(! val) return;
            //         let portraies = this.listPortray;
            //         this.timer = setInterval( ()=> {
            //             this.changeArtistImg(portraies);
            //         }, 8 * 1000)
            //     }
            // }
        },
        mounted(){
            this.loadingShow();
            this.BackTop();

            let background = this.wallpaperRandom();
            if(background){
                this.background = background;
                setTimeout( ()=> {
                    this.loadingHide();
                }, 500)
            }
            initialApi.random_wallpaper().then(
                response => {
                    if(response.status === 200){
                        this.background = response.data.route;
                    }
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500)
                },
                error => {
                    this.$notify.error({title: '错误', message: error});
                    setTimeout( ()=> {
                        this.loadingHide();
                    }, 500)
                }
            )
        }
    }
</script>


<style scoped>
    #music {
        width: 100%;
    }
    #site-title, #layout-content {
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    #layout-content .layout {
        width: 100%;
        height: 550px;
        margin-top: 2.5em;
        max-width: 1200px;
        position: relative;
        border-radius: 4px;
        background-size: cover !important;
    }
    .top-aplayer {
        opacity: .0;
        width: 100%;
        height: 460px;
    }
    .subject-aplayer {
        position: static;
        width: 100%;
    }
</style>

<style>
    #layout-content .layout .aplayer {
        margin: 0 !important;
        overflow: visible !important;
        position: static !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        background: rgba(255, 255, 255, .6) !important;
    }
    #layout-content .layout .aplayer-pic {
        opacity: .6 !important;
        background-color: rgba(255, 255, 255, .6);
    }

    #layout-content .layout .aplayer .aplayer-body .aplayer-info .aplayer-music .aplayer-title {
        font-size: 20px !important;
        font-family: "华文楷体" !important;
    }
    #layout-content .layout .aplayer .aplayer-body .aplayer-info .aplayer-music .aplayer-author {
        font-size: 15px !important;
        font-family: "华文楷体" !important;
    }

    #layout-content .layout .aplayer-lrc::before {
        height: 0px !important;
    }
    #layout-content .layout .aplayer-lrc::after {
        height: 0px !important;
    }
    #layout-content .layout .aplayer .aplayer-body .aplayer-info .aplayer-lrc {
        margin-top: -2px !important;
    }
    #layout-content .layout .aplayer-lrc p {
        font-size: 15px !important;
        font-family: "华文楷体" !important;
    }
    #layout-content .layout .aplayer .aplayer-lrc .aplayer-lrc-contents .aplayer-lrc-current {
        font-family: "华文楷体" !important;
        font-weight: 1000 !important;
        color: red !important;
        font-size: 1.02em;
    }

    #layout-content .layout .aplayer-volume {
        background: rgba(0, 0, 255, .6) !important;
    }

    #layout-content .layout .aplayer.aplayer-withlist .aplayer-list {
        position: absolute !important;
        top: -350px !important;
        right: 0 !important;
        width: 420px !important;
        height: 350px !important;
        overflow-y: scroll !important;
        background-color: rgba(255, 255, 255, .6);
    }
    #layout-content .layout .aplayer .aplayer-list .aplayer-list-cur{
        background: rgba(0, 0, 255, .6) !important;
    }

    #layout-content .layout .aplayer-list ol li .aplayer-list-title {
        font-size: 1.28em !important;
        font-family: "华文楷体" !important;
        color: black !important;
    }

    #layout-content .layout .aplayer-list ol li .aplayer-list-author {
        font-size: 1.18em !important;
        font-family: "华文楷体" !important;
    }
</style>