

// 作者个人信息相关配置
export default {

    namespaced: true,

    state: {
        information: null,
        author: null
    },

    getters: {

        getLogo: state => {
            if(! state.information) return;
            return state.information.logo;
        },

        getTitle: state => {
            if(! state.information) return;
            return state.information.title;
        },

        getCard: state => {
            if(! state.information) return;
            const card = {
                name: state.information?.name,
                head: state.information?.head,
                autograph: state.information?.autograph,
                github: state.information?.github,
                email: state.information?.email
            };
            return card;
        },

        getDesigned: state => {
            if(! state.information) return;
            const designed = {
                origination: state.information?.origination,
                logo: state.information?.logo,
                name: state.information?.name,
                position: state.information?.position,
                registration: state.information?.registration
            };
            return designed;
        },

        getSignature: state => {
            if(! state.information) return;
            const signature = {
                wechatpay: state.information?.wechatpay,
                alipay: state.information?.alipay,
                official: state.information?.official
            };
            return signature;
        },
        
        searchAuthor: state => {
            return (val) => {
                if (!val || val === '') return;
                return state.author?.find(author => author.uuid === val);
            };
        }
    }
}